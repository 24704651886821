import React, { useState } from 'react';
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Stack, styled } from '@mui/system';
import commonUtils from '../../utils/common/common';
import { IconButtonStyle } from '../Modal/style';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
  },
}));

const UpdatePassword = ({
  onSetPassword,
  setOpenPasswordPopup,
  openPasswordPopup,
  setUpdatePasswordRequest,
}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (password !== event.target.value) {
      setPasswordsMatch(false);
      setErrorMessage('Passwords not match');
    } else if (!commonUtils.passwordValidate(password)) {
      setPasswordsMatch(false);
      setErrorMessage('Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, a special character, and have no spaces');
    } else {
      setPasswordsMatch(true);
      setErrorMessage('');
    }
  };

  const handleSetPassword = () => {
    onSetPassword(password, confirmPassword);
    setPassword('');
    setConfirmPassword('');
    setPasswordsMatch(true);
    setErrorMessage('');
  };

  const handleClose = () => {
    setOpenPasswordPopup(false);
    setUpdatePasswordRequest({});
  };

  return (
    <>
      <StyledDialog
        open={openPasswordPopup}
        onClose={handleClose}
      >
        <DialogTitle>
          Update Password
        </DialogTitle>
        <Divider />
        <IconButtonStyle
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButtonStyle>
        <DialogContent>
          <Grid item xs={12} mb={3}>
            <div
              style={{
                background: '#3f639838',
                padding: '15px',
                borderRadius: '8px',
                marginBottom: '10px',
              }}
            >
              <Typography variant="body1">
                <strong>Ensure that these requirements are met</strong>
                <br />
                Minimum 8 characters long, uppercase & symbol
              </Typography>
            </div>
          </Grid>
          <Stack sx={{ minWidth: '480px', marginBottom: '20px' }}>
            <TextField
              sx={{ width: '80%' }}
              label="Password"
              value={password}
              type={showPassword ? 'text' : 'password'}
              onChange={handlePasswordChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack sx={{ minWidth: '480px' }}>
            <TextField
              sx={{ width: '80%' }}
              label="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={!passwordsMatch}
              helperText={errorMessage}
              type={showPassword ? 'text' : 'password'}
              fullWidth
            />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleSetPassword} type="submit" autoFocus color="primary" variant="contained">
            Submit
          </Button>
          <Button autoFocus color="primary" variant="text" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default UpdatePassword;
UpdatePassword.propTypes = {
  onSetPassword: PropTypes.func.isRequired,
  openPasswordPopup: PropTypes.bool.isRequired,
  setOpenPasswordPopup: PropTypes.func.isRequired,
  setUpdatePasswordRequest: PropTypes.func.isRequired,
};
