import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
import { useCommonContext } from '../header/context';
import { UserPermission, UserRole } from '../../utils/constants';
import companyService from '../../services/company.service';
import reportService from '../../services/report.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { jiraAccountPageByCurrentUser } from '../../services/integrations';
import { CustomPagination, StyledTablePagination, tableStyles } from '../header/style';

const JiraIntegration = () => {
  const classes = tableStyles();
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [projectId, setprojectId] = useState(null);
  const [project, setProject] = useState([]);
  const [jiraProjects, setJiraProjects] = useState([]);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useCommonContext();

  useEffect(async () => {
    if (companyId && projectId) {
      try {
        const data = await jiraAccountPageByCurrentUser({
          companyId, projectId, page: page > 0 ? page - 1 : 0, rowsPerPage,
        });
        setJiraProjects(data.content);
        setTotalPages(data.totalPages);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.data.message}`,
        });
      }
    }
  }, [page, rowsPerPage, companyId, projectId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const companyList = async () => {
    try {
      const res = await companyService.getAll();
      setCompanyId(res[0]?.companyId);
      setprojectId(res[0]?.projects[0]?.projectId);
      setCompanyDropdown(res);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
  };

  const handleCompanyChange = (e) => {
    const id = e.target.value;
    setCompanyId(id);
    setprojectId('');
  };

  useEffect(() => {
    if (user?.role?.roleName === UserRole.ADMIN) {
      setCompanyId(user?.company?.companyId);
      setprojectId(user?.company?.projectResponses[0]?.projectId);
    }
    companyList();
  }, [user?.role?.roleId]);

  const getProjectName = async () => {
    if (companyId) {
      try {
        const res = await reportService.getProjectById(companyId);
        setProject(res);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.data.message}`,
        });
      }
    }
  };

  useEffect(() => {
    getProjectName();
  }, [user?.role?.roleId, companyId]);

  return (
    <>
      <Box
        sx={{
          borderRadius: '4px',
          background: '#fff',
        }}
      >
        {user?.role?.userPermissionsList.includes(UserPermission.JIRA_INTEGRATION) && (
          <>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel size="small">
                      Company
                    </InputLabel>
                    {companyDropdown && companyDropdown.length > 0
                      && <Select
                        size="small"
                        label="Company"
                        value={companyId}
                        onChange={handleCompanyChange}
                        sx={{ borderRadius: '20px' }}
                      >
                        {!companyDropdown.length
                          && <MenuItem style={{ color: '#999' }}>
                            No Company Found
                          </MenuItem>}
                        {companyDropdown && companyDropdown?.map((menuitem) => (
                          <MenuItem value={menuitem?.companyId} key={menuitem?.companyId}>
                            {menuitem?.companyName}
                          </MenuItem>
                        ))}
                      </Select>}
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel size="small">
                      Projects
                    </InputLabel>
                    {project && project.length > 0
                      && <Select
                        size="small"
                        defaultValue=""
                        label="Projects"
                        value={projectId || ''}
                        onChange={(e) => { setprojectId(e.target.value); }}
                        sx={{ borderRadius: '20px' }}
                      >
                        {project?.length ? project?.map((menuitem) => (
                          <MenuItem value={menuitem?.projectId} key={menuitem?.projectId}>
                            {menuitem?.projectName}
                          </MenuItem>
                        )) : <MenuItem style={{ color: '#999' }}>
                          No Project Found
                        </MenuItem>}
                      </Select>}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={4}>
                <Paper className={classes.summaryBox}>
                  <Box className={classes.summaryBoxIn}>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.headerCell}>Project</TableCell>
                            <TableCell className={classes.headerCell}>Domain Name</TableCell>
                            <TableCell className={classes.headerCell}>Lead Id</TableCell>
                            <TableCell className={classes.headerCell}>Key</TableCell>
                            {/* <TableCell className={classes.headerCell}>Action</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {jiraProjects?.length ? jiraProjects?.map((row) => (
                            <TableRow key={row?.jiraProjectId}>
                              <TableCell>{row?.name}</TableCell>
                              <TableCell>{row?.jiraAccountDomainName}</TableCell>
                              <TableCell>{row?.leadAccountId}</TableCell>
                              <TableCell>{row?.key}</TableCell>
                            </TableRow>
                          )) : (<TableRow>
                            <TableCell colSpan={5} align="center">
                              No Jira Account Setup.
                            </TableCell>
                          </TableRow>)}
                        </TableBody>
                      </Table>
                      {totalPages > 0 ? (
                        <Box className={classes.paginationContainer}>
                          <StyledTablePagination
                            count={totalPages * rowsPerPage}
                            totalPages={totalPages}
                            page={page - 1}
                            onPageChange={handleChangePage}
                            ActionsComponent={() => <CustomPagination
                              count={totalPages}
                              page={page}
                              onChange={handleChangePage}
                              variant="outlined"
                              shape="rounded"
                              showFirstButton
                              showLastButton
                            />}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Box>
                      ) : <></>}
                    </TableContainer>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {snackbar.state && (
        <NavigateSnackbar
          snackbarObj={snackbar}
          setSnackbar={setSnackbar}
        />
      )}
    </>
  );
};

export default JiraIntegration;
