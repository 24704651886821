import { makeStyles, createStyles } from '@mui/styles';
import {
  styled,
} from '@mui/material/styles';
import {
  TableCell,
  tableCellClasses,
  TableRow,
  Box,
  Pagination,
  TablePagination,
} from '@mui/material';

const headerStyles = makeStyles(() => createStyles({
  DashboardText: {
    color: '#3f6398',
    fontSize: 16,
    textTransform: 'none',
    textAlign: 'center',
    marginLeft: 5,
  },
  ReportText: {
    font: 'Inter, sans-serif',
    fontWeight: 400,
    color: '#3f6398',
    fontSize: 16,
    marginLeft: 13.84,
    fontStyle: 'normal',
  },
  parentBox: {
    background: 'white',
  },
  selectDropdown: {
    marginLeft: 19,
    height: 30.87,
    borderBlockColor: '#3f6398',
    color: '#3f6398',
    fontSize: '16px',
    textTransform: 'none',
  },
  iconButton: {
    color: '#3f6398',
  },
  reportsDropdown: {
    width: 155,
    height: 116,
  },
  reportsDropdownFields: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      background: 'rgba(11, 35, 54, 0.1)',
    },
  },
  allDropdownFields: {
    textAlign: 'left',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'start',
    padding: 13,
    position: 'absolute',
    width: '220px',
    color: '#000',
    '&:hover': {
      background: 'rgba(11, 35, 54, 0.1)',
    },
  },
  allspanDropdownField: {
    margin: '5px 0px',
  },
  uploadButton: {
    position: 'absolute',
    background: 'linear-gradient(146deg,#3f6398 8%,#3f6398 93%)',
    color: '#fff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 4,
    fontWeight: 800,
    marginRight: 20,
    marginBottom: 10,
    marginTop: 10,
    textTransform: 'none',
    '&:hover': {
      background: 'linear-gradient(146deg,#0dd3dd 8%,#027b81 93%)',
    },
  },
  uploadReportButton: {
    height: 30,
    width: 132,
    background: '#3f6398',
    borderRadius: 50,
    textTransform: 'none',
    '&:hover': {
      background: '#3f6398',
    },
  },
  headerAvatar: {
    width: 50,
    height: 50,
  },
  avatarList: {
    '& .MuiMenu-list': {
      paddingTop: 0,
      paddingBottom: 0,
      width: 155,
      height: 150,
    },
  },
  avatarMenuItemList: {
    height: 18,
    width: 157,
    paddingTop: 3,
    paddingBottom: 4,
    color: '#3f6398',
    fontWeight: 800,
  },
  materialIcon: {
    '&:empty': {
      width: 0,
    },
    fontSize: 18,
    marginLeft: 13,
    paddingTop: 5,
    whiteSpace: 'pre-wrap',
    color: '#3f6398',
  },
  AccountMenu: {
    overflow: 'visible',
    marginTop: 1,
    paddingTop: 0,
    paddingBottom: 0,

  },
  avatarListTextTypography: {
    height: 18,
    width: 90,
    textAlign: 'left',
    alignItems: 'center',
    fontWeight: 800,
    color: '#3f6398',
    marginLeft: 12,
  },
  mainBox: {
    flexGrow: 1,
    zIndex: 2,
  },
}));

const staticStyleObj = {

  toolbar: {
    display: 'flex',
    justifyContent: { sm: 'space-between', xs: 'center' },
  },
  DashboardButton: {
    fontWeight: '500',
    fontSize: '16px',
    textTransform: 'capitalize',
    margin: '0px 13px',
    '&:hover': {
      background: 'transparent',
    },
  },
  DashboardItem: {
    fontWeight: '500',
    paddingLeft: '6px',
  },
  DashboardMenuItem: {
    color: '#3f6398',
  },
  DashboardBox: {
    display: { sm: 'flex', xs: 'grid' },
    alignItems: 'center',
    marginX: { sm: 0, xs: 0 },
  },
  uploadBox: {
    display: 'flex',
    justifyContent: { sm: 'space-between', xs: 'flex-start' },
    paddingTop: 0,
    alignItems: 'center',
  },
  avatarButton: {
    p: 0,
  },
  avatarMenu: {
    mt: '45px',
  },
  notification: {
    mr: 3,
  },
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    // width: '100%',
    textAlign: 'center',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderCollapse: 'separate !important',
  '&:last-child td, &:last-child th': {
    border: theme.spacing(0),
  },
  '&:hover': {
    backgroundColor: '#f0f0f0',
    transform: 'scale(1.02)',
    transition: 'transform 0.2s ease-in-out',
  },
}));

const StyledDailogAction = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '20px',
}));

/* New Header and navbar styles start */
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #ececec',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    paddingTop: theme.spacing(3),
  },
  drawerContainer: {
    overflow: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(7.5),
    cursor: 'pointer',
  },
  selectBox: {
    width: '75%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1, 2, 1, 4),
    '& .MuiSelect-select': {
      padding: theme.spacing(1, 1),
    },
  },
  inActive: {
    color: '#000',
    cursor: 'pointer',
    width: '80%',
    margin: theme.spacing(1, 2),
    letterSpacing: '0.5px',
  },
  active: {
    cursor: 'pointer',
    fontWeight: 'bolder',
    color: '#000',
    width: '80%',
    background: '#F3EDDE',
    margin: theme.spacing(1, 2),
    borderRadius: '10px',
    letterSpacing: '0.5px',
  },
  activeIcon: {
    color: '#000',
    borderBottom: '0',
  },
  inActiveIcon: {
    color: '#aeaeae',
    borderBottom: '0',
  },
  itemIcon: {
    minWidth: theme.spacing(4),
  },
  appBarInside: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    marginRight: theme.spacing(1),
    maxWidth: '200px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  contactButton: {
    backgroundColor: '#1d6ae5',
    boxShadow: 'none',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#165bb5',
    },
  },
  content: {
    flexGrow: 1,
  },
}));
/* New Header and navbar styles ends */

/* Home styles satrts */
const homeStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #e9e9e9',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    display: 'inline-flex',
    borderBottom: '4px solid #FFC72B',
    fontWeight: '600',
    paddingBottom: 8,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #ececec',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    marginRight: theme.spacing(1),
    maxWidth: '200px',
  },
  downloadIcon: {
    color: '#fff',
    background: '#1d6ae5',
    border: '1px solid #f0f0f0',
    minWidth: '16px',
    '&:hover': {
      background: '#1755b7',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headerHeading: {
    marginLeft: theme.spacing(1),
    background: '#fff',
  },
  gridContainer: {
    marginBottom: theme.spacing(4),
  },
  card: {
    padding: theme.spacing(2.5),
    textAlign: 'left',
    border: '1px solid #FFC72B',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 4px 4px 0px #00000012',
  },
  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitle: {
    color: '#666666',
    textAlign: 'center',
    margin: theme.spacing(1, 0, 0, 0),
  },
  cardLeft: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  latestTestExecution: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: '1px solid #FFC72B',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 4px 4px 0px #00000012',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  paginationContainer: {
    marginTop: theme.spacing(3),
  },
  redText: {
    color: '#ff0000',
    padding: theme.spacing(0, 0, 1, 0),
    fontWeight: '600',
  },
  greenText: {
    color: '#5fd726',
    padding: theme.spacing(0, 0, 1, 0),
    fontWeight: '600',
  },
  closeIcon: {
    color: '#ff0000',
    marginRight: theme.spacing(1),
  },
  chipSuccess: {
    color: '#fff',
    backgroundColor: '#5FD726',
    borderColor: '#5FD726',
    height: '25px',
  },
  chipFailure: {
    color: '#fff',
    backgroundColor: '#FF0000',
    borderColor: '#FF0000',
    height: '25px',
  },
  chipSkipped: {
    color: '#fff',
    backgroundColor: '#FFC72B',
    borderColor: '#FFC72B',
    height: '25px',
  },
  chipIgnored: {
    color: '#fff',
    backgroundColor: '#000000',
    borderColor: '#000000',
    height: '25px',
  },
  chipFeatures: {
    color: '#d09c0c',
    borderColor: '#ffd358',
    margin: theme.spacing(0, 0, 1),
  },
  chipLabel: {
    color: '#fff',
    borderColor: '#1D6AE5',
    backgroundColor: '#1D6AE5',
    height: '25px',
  },
  tab: {
    maxWidth: '230px',
    minWidth: '60px',
  },
  balloon: {
    position: 'relative',
    color: '#333',
    backgroundColor: '#e8f0fe',
    borderRadius: theme.shape.borderRadius,
    maxWidth: '230px',
    minHeight: '30px',
    minWidth: '60px',
    overflow: 'visible',
    padding: theme.spacing(0.5),
    border: '1px solid #007bff',
    fontWeight: 'bold',
    '&::after': {
      content: '""',
      color: '#333',
      position: 'absolute',
      bottom: '-8px',
      left: '20px',
      backgroundColor: '#e8f0fe',
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderTop: '8px solid #007bff',
    },
  },
  contactButton: {
    backgroundColor: '#1d6ae5',
    boxShadow: 'none',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#165bb5',
    },
  },
  ToggleButtonGroup: {
    background: '#f9f9f9',
    display: 'flex',
    width: 'fit-content',
    border: '1px solid #e1e1e1',
    borderRadius: theme.shape.borderRadius,
    maxHeight: '40px',
  },
}));
/* home styles ends */

/* Build Run Styles */
const buildRunStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    background: '#f5f5f5',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #ececec',
    padding: theme.spacing(2),
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    marginRight: theme.spacing(1),
    maxWidth: '200px',
  },
  heading: {
    borderBottom: '1px solid #e9e9e9',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subHeader: {
    color: theme.palette.text.secondary,
  },
  title: {
    borderBottom: '4px solid #FFC72B',
    fontWeight: '600',
    paddingBlock: 8,
  },
  summaryItem: {
    marginBottom: theme.spacing(1),
  },
  summaryValue: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  chip: {
    height: 24,
  },
  browserSummaryItem: {
    padding: theme.spacing(2),
  },
  browserSummaryTitle: {
    fontWeight: 'bold',
  },
  chartContainer: {
    padding: theme.spacing(2),
  },
  tabContent: {
    padding: theme.spacing(2),
  },
  summaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    border: '1px solid #f0f0f0',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  summaryBoxUnique: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    border: '1px solid #f0f0f0',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  summaryHeading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  summaryBoxIn: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  summaryGraphBoxIn: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  testResultSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: 2,
    '& .MuiPaper-root': {
      width: '48%',
    },
  },
  moreIcon: {
    color: '#a9a9a9',
  },
  metric: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: theme.spacing(2),
  },
  metricIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  metricValue: {
    fontWeight: 'bold',
  },
  metricTitle: {
    color: '#707070',
  },
  divider: {
    height: '60px !important',
    margin: theme.spacing(1, 2),
    background: '#ebebeb',
  },
  dividerHori: {
    margin: theme.spacing(2, 0),
    background: '#ebebeb',
  },
  chartIcon: {
    height: 30,
    marginBottom: theme.spacing(1),
    color: '#ff0000',
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    boxShadow: 'none',
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  headerCell: {
    fontWeight: 'bold',
  },
  tableTitle: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  tabsMain: {
    background: '#e1e1e1',
    width: 'fit-content',
    minHeight: '25px',
    alignItems: 'center',
    border: '1px solid #1D6AE5',
    borderRadius: theme.shape.borderRadius,
    '& .MuiTabs-flexContainer': {
      alignItems: 'center',
      '& .MuiTab-root': {
        minHeight: '34px',
      },
    },
  },
  balloonInActive: {
    position: 'relative',
    maxWidth: '250px',
    minHeight: '25px',
    minWidth: '60px',
    textTransform: 'capitalize',
    color: '#FFF !important',
    padding: theme.spacing(1),
    backgroundColor: '#1D6AE5',
  },
  balloon: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    maxWidth: '250px',
    minHeight: '25px',
    minWidth: '60px',
    textTransform: 'capitalize',
    padding: theme.spacing(1),
  },
  downloadIcon: {
    color: '#fff',
    background: '#1d6ae5',
    border: '1px solid #f0f0f0',
    minWidth: '16px',
    '&:hover': {
      background: '#1755b7',
    },
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    background: '#f5f5f5',
    borderRadius: theme.shape.borderRadius,
  },
  categoryColor: {
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    marginRight: theme.spacing(1),
  },
  categoryCount: {
    color: '#707070',
    minWidth: '30%',
    textAlign: 'left',
  },
  totalFailures: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(1, 1, 0, 0),
  },
  verticalBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '18px',
    marginBottom: theme.spacing(1),
  },
  barSegment: {
    width: '100%',
    '&:first-child': {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    '&:last-child': {
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
  },
  barSegmentNonActive: {
    width: '100%',
    '&:first-child': {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
    '&:last-child': {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
  },
  collapseBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    border: '1px solid #E1E1E1',
    borderRadius: theme.spacing(1),
    backgroundColor: '#F5F5F5',
    cursor: 'pointer',
  },
  icon: {
    width: '24px',
    height: '24px',
    backgroundColor: '#ff1744',
    color: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
  detailsBox: {
    padding: theme.spacing(2),
    border: '1px solid #f0f0f0',
    borderRadius: theme.spacing(1),
    backgroundColor: '#fff',
    marginTop: theme.spacing(1),
  },
  errorIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    backgroundColor: '#ffe5e5',
    border: '1px solid #fccece',
    marginRight: theme.spacing(2),
    '& .MuiTypography-root': {
      color: '#ff0000',
    },
  },
  details: {
    padding: theme.spacing(2),
    background: '#f9f9f9',
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  chipSuccess: {
    '&:hover': {
      background: '#3c8718 !important',
      borderColor: '#3c8718 !important',
    },
    color: '#fff',
    backgroundColor: '#5FD726',
    borderColor: '#5FD726',
    height: '25px',
  },
  chipFailure: {
    '&:hover': {
      background: '#870404  !important',
      borderColor: '#870404 !important',
    },
    color: '#fff',
    backgroundColor: '#FF0000',
    borderColor: '#FF0000',
    height: '25px',
  },
  chipSkipped: {
    '&:hover': {
      background: '#a37800 !important',
      borderColor: '#a37800 !important',
    },
    color: '#fff',
    backgroundColor: '#FFC72B',
    borderColor: '#FFC72B',
    height: '25px',
  },
  chipIgnored: {
    '&:hover': {
      background: '#626161 !important',
      borderColor: '#626161 !important',
    },
    color: '#fff',
    backgroundColor: '#000000',
    borderColor: '#000000',
    height: '25px',
  },
  chipFeatures: {
    color: '#d09c0c',
    borderColor: '#ffd358',
    margin: theme.spacing(0, 0, 1),
  },
  chipLabel: {
    color: '#fff',
    borderColor: '#1D6AE5',
    backgroundColor: '#1D6AE5',
    height: '25px',
  },
  chipFailed: {
    color: '#fff',
    backgroundColor: '#FF0000',
    height: '25px',
    fontWeight: '600',
  },
  chipPassed: {
    color: '#fff',
    backgroundColor: '#5FD726',
    height: '25px',
    fontWeight: '600',
  },
}));
/* BUild Run Styles */

/* Build Run Styles */
const projectStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    background: '#f5f5f5',
  },
  actionButton: {
    backgroundColor: '#1d6ae5',
    boxShadow: 'none',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#165bb5',
    },
  },
  heading: {
    borderBottom: '1px solid #e9e9e9',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subHeader: {
    color: theme.palette.text.secondary,
  },
  title: {
    borderBottom: '4px solid #FFC72B',
    fontWeight: '600',
    paddingBlock: 8,
  },
  summaryItem: {
    marginBottom: theme.spacing(1),
  },
  summaryValue: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  summaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 2, 2, 2),
    border: '1px solid #f0f0f0',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  summaryHeading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  moreIcon: {
    color: '#a9a9a9',
  },
  summaryBoxIn: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  summaryBoxInTeam: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: '20px',
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    boxShadow: 'none',
  },
  headerCell: {
    fontWeight: 'bold',
  },
}));

/* Dashboard Styles Start */
const dashboardStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    background: '#f5f5f5',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #ececec',
    padding: theme.spacing(2),
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    marginRight: theme.spacing(1),
    maxWidth: '200px',
  },
  downloadIcon: {
    color: '#fff',
    background: '#1d6ae5',
    border: '1px solid #f0f0f0',
    minWidth: '16px',
    '&:hover': {
      background: '#1755b7',
    },
  },
  heading: {
    borderBottom: '1px solid #e9e9e9',
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  summaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    border: '1px solid #f0f0f0',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  summaryHeading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  summaryTitle: {
    fontSize: '19px',
    fontWeight: 600,
  },
  subtitle: {
    fontSize: '15px',
    fontWeight: 400,
  },
  summaryBoxIn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      borderRight: '1px solid #ebebeb',
    },
  },
  metric: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  metricIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    textAlign: 'center',
    width: '100%',
  },
  metricValue: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    boxShadow: 'none',
    maxHeight: 580,
    overflow: 'auto',
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[200],
    position: 'sticky',
    top: 0,
  },
  headerCell: {
    fontSize: '17px',
    fontWeight: '600',
  },
  tab: {
    maxWidth: '230px',
    minWidth: '60px',
  },
  balloon: {
    position: 'relative',
    color: '#333',
    backgroundColor: '#e8f0fe',
    borderRadius: theme.shape.borderRadius,
    maxWidth: '230px',
    minHeight: '30px',
    minWidth: '60px',
    overflow: 'visible',
    padding: theme.spacing(0.5),
    border: '1px solid #007bff',
    fontWeight: 'bold',
    '&::after': {
      content: '""',
      color: '#333',
      position: 'absolute',
      bottom: '-8px',
      left: '20px',
      backgroundColor: '#e8f0fe',
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderTop: '8px solid #007bff',
    },
  },
  paginationContainer: {
    marginTop: theme.spacing(3),
  },
  ToggleButtonGroup: {
    background: '#f9f9f9',
    display: 'flex',
    width: 'fit-content',
    border: '1px solid #e1e1e1',
    borderRadius: theme.shape.borderRadius,
    maxHeight: '40px',
  },
}));
/* Dashboard Styles Ends */

/* custom pagination Starts */
const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  border: '0',
  '& .MuiTablePagination-toolbar': {
    padding: '0',
    width: '100%',
  },
  '& .MuiPagination-root': {
    width: '50%',
  },
  '& .MuiTablePagination-input': {
    border: '1px solid #e3e3e3',
    borderRadius: '4px',
    marginRight: theme.spacing(0),
  },
  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
    margin: theme.spacing(0, 2),
  },
  '& .MuiTablePagination-actions': {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPagination-ul': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 'max-content',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: '8px',
    margin: theme.spacing(0.5),
    padding: '8px 12px',
    border: '1px solid #e0e0e0',
    '&.Mui-selected': {
      backgroundColor: '#1976d2', // Primary color for selected page
      color: '#fff',
      fontWeight: 'bold',
    },
    '&:hover': {
      color: '#000',
      backgroundColor: '#f5f5f5', // Hover effect for buttons
    },
  },
  '& .MuiPaginationItem-ellipsis': {
    border: 'none', // No border for ellipsis
    background: 'none',
  },
  '& .MuiPaginationItem-previousNext': {
    minWidth: '32px', // Ensure uniform size for the previous/next buttons
  },
}));
/* custom pagination ends */

/* Slack Jira Tables */
const tableStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    background: '#f5f5f5',
  },
  actionButton: {
    backgroundColor: '#1d6ae5',
    boxShadow: 'none',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#165bb5',
    },
  },
  heading: {
    borderBottom: '1px solid #e9e9e9',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subHeader: {
    color: theme.palette.text.secondary,
  },
  title: {
    borderBottom: '4px solid #FFC72B',
    fontWeight: '600',
    paddingBlock: 8,
  },
  summaryItem: {
    marginBottom: theme.spacing(1),
  },
  summaryValue: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  summaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 2, 2, 2),
    border: '1px solid #f0f0f0',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  summaryHeading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  moreIcon: {
    color: '#a9a9a9',
  },
  summaryBoxIn: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  summaryBoxInTeam: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: '20px',
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    boxShadow: 'none',
  },
  headerCell: {
    fontWeight: 'bold',
  },
}));
/* Slack jira table */

export {
  useStyles,
  homeStyles,
  headerStyles,
  buildRunStyles,
  projectStyles,
  dashboardStyles,
  tableStyles,
  staticStyleObj,
  StyledTableCell,
  StyledTableRow,
  StyledDailogAction,
  StyledTablePagination,
  CustomPagination,
};
