import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { InviteStatus } from '../../utils/constants';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
  },
}));

export default function ActionModal({
  opend, handleClose, action, handleAction, isDelete, isBlock, isActive,
}) {
  return (
    <StyledDialog
      open={opend}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">
        Are you sure?
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {isActive && `Do you want to ${action === InviteStatus.ACCEPTED ? 'UNBLOCK' : 'BLOCK'} this user?`}
          {isBlock && 'Do you really want to BLOCK this user?'}
          {isDelete && 'Do you really want to DELETE this user?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {isActive && <Button onClick={handleAction} color="error">
          {action === InviteStatus.ACCEPTED ? 'Accept' : 'Active'}
        </Button>}
        {isBlock && <Button onClick={handleAction} color="error">Block</Button>}
        {isDelete && <Button onClick={handleAction} color="error">Delete</Button>}
      </DialogActions>
    </StyledDialog>
  );
}

ActionModal.propTypes = {
  opend: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  isDelete: PropTypes.bool,
  isBlock: PropTypes.bool,
  isActive: PropTypes.bool,
  action: PropTypes.string,
};
ActionModal.defaultProps = {
  opend: PropTypes.bool,
  isDelete: false,
  isBlock: false,
  isActive: false,
  action: '',
};
