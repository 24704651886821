/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  CssBaseline,
  Container,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Breadcrumbs,
  Button,
  Collapse,
  IconButton,
  Chip,
  Tooltip,
  Skeleton,
  Badge,
  Avatar,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePDF } from 'react-to-pdf';
import { buildRunStyles } from '../header/style';
import jenkinsImg from '../../assets/jenkins.png';
import {
  CypressTestStatus,
  FileType, HtmlStatus, PlatwrightTestStatus, TestStatus,
  UserRole, browserEnum, browserIcons, chipEnum,
  plateformIcons,
  platformEnum,
} from '../../utils/constants';
import {
  cypressResultListById, playwrightResultListById, resultListById, resultListHTMLById,
} from '../../services/uploadFile.service';
import {
  extractBuildSummaryCypress,
  extractBuildSummaryHTML,
  extractBuildSummaryPlaywright,
  extractBuildSummaryXML,
} from './FilterChartsData';
import BuildChart from '../chart/BuildSummaryChart';
import HorizontalStackedBarChart from '../chart/BuildHistoryChart';
import LineChartWithStats from '../chart/LineStatchart';
import reportService from '../../services/report.service';
import commentService from '../../services/comment.service';
import Loader from '../../utils/loader/Loader';
import UserCircleIcon from '../../assets/circle-user.png';
import ClockIcon from '../../assets/clock.png';
import stopwatchIcon from '../../assets/stopwatch.png';
import CancelIcon from '../../assets/circle-xmark-white.svg';
import CheckIcon from '../../assets/check-circle-white.svg';
import Logo from '../../assets/test-report-logo-dark.svg';
import ChatIcon from '../../assets/images/ai-button.png';
import {
  segregateCypressData, segregatePlaywrightData, segregateSeleniumData, segregateSeleniumHTMLData,
} from '../Home/TestData';
import TestReportList from './TestPage/TestReportList';
import useHandleChipClick from '../../utils/FilterChipHook';
import { useCommonContext } from '../header/context';
import route from '../../utils/route';
import { toTitleCase } from '../../utils';
import captureScreenshot from '../../utils/caputre';
import AnalyzingLoader from '../Analysis';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import useMeta from '../../utils/useMeta';

const TabLabels = ['Summary', 'Tests'];

const BuildRun = () => {
  const location = useLocation();
  const { id: reportId, reportType } = useParams();
  const handleChipClick = useHandleChipClick();
  const navigate = useNavigate();
  const classes = buildRunStyles();
  const headerRef = useRef();
  const downloadRef = useRef();
  const {
    user,
    setBuildRunTab,
    activeItem,
    setActiveItem,
    buildRunTab,
    setReportType,
  } = useCommonContext();
  const [expanded, setExpanded] = useState(null);
  const [chartData, setChartData] = useState();
  const [testReport, setTestReport] = useState();
  const [buildHistory, setBuildHistory] = useState();
  const [buildSummary, setBuildSummary] = useState();
  const [tags, setTags] = useState({});
  const [openTest, setOpenTest] = useState(false);
  const [bugTab, setBugTab] = useState();
  const [downloading, setDownloading] = useState(false);
  const [imageBlob, setImageBlob] = useState();
  const [analyzing, setAnalyzing] = useState(false);
  const [selectedTests, setSelectedTests] = useState({
    id: '', test: {}, testName: '', project: '',
  });
  const [tagsCount, setTagsCount] = useState({
    productBug: 0,
    automationBug: 0,
    noDefect: 0,
    toInvestigate: 0,
    environmentIssue: 0,
  });
  const [reportData, setReportData] = useState({
    reportName: '',
    companyId: '',
    projectId: '',
  });

  const [buildRunData, setBuildRunData] = useState({
    alwaysFailing: 0,
    flakiness: 0,
    newFailure: 0,
    mutedTest: 0,
    totalTest: 0,
  });
  const [buildStability, setBuildStability] = useState();
  const [uniqueErrors, setUniqueErrors] = useState();

  const [loading, setLoading] = useState(false);

  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: `${TabLabels[buildRunTab]}-${chartData?.testReportName}-${Date.now()}.pdf`,
    page: { margin: 2, format: 'A4', orientation: 'portrait' },
  });

  const generatePDF = async () => {
    try {
      setDownloading(true);
      if (headerRef.current) {
        headerRef.current.style.display = 'block';
      }
      if (downloadRef.current) {
        downloadRef.current.style.display = 'none';
      }

      await toPDF();

      if (headerRef.current) {
        headerRef.current.style.display = 'none';
      }
      if (downloadRef.current) {
        downloadRef.current.style.display = 'block';
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDownloading(false);
    }
  };

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleChange = (event, newValue) => {
    setBuildRunTab(newValue);
    if (!newValue) setOpenTest(false);
  };

  const handleAssistant = async () => {
    try {
      setAnalyzing(true);
      const res = await captureScreenshot(targetRef);
      if (res) {
        setImageBlob(res);
      }
    } catch (error) {
      setAnalyzing(false);
    }
  };

  const {
    productBug,
    automationBug,
    noDefect,
    toInvestigate,
    environmentIssue,
  } = tagsCount || {};

  const totalFailures = buildSummary?.failed || 1;

  useEffect(() => {
    const processLocationState = () => {
      if (location.state) {
        const { message, reportUpload } = location.state;

        if (message && reportUpload) {
          setSnackbar({
            navigateText: '',
            severity: 'success',
            state: true,
            message,
          });
        }
        navigate(location.pathname, { replace: true });
      }
    };
    processLocationState();
  }, [location, navigate]);
  useEffect(() => {
    const totalCount = automationBug + environmentIssue + productBug + noDefect;
    setTagsCount({
      ...tagsCount,
      toInvestigate: (buildSummary?.failed || 1) - totalCount,
    });
  }, [buildSummary]);

  const categories = [
    {
      label: 'To be Investigated',
      count: toInvestigate,
      color: '#D9D9D9',
    },
    {
      label: 'Automation Bug',
      count: automationBug,
      percentage: `${((automationBug / totalFailures) * 100).toFixed(2)}%`,
      color: '#FFC72B',
    },
    {
      label: 'Environment Issue',
      count: environmentIssue,
      percentage: `${((environmentIssue / totalFailures) * 100).toFixed(2)}%`,
      color: '#5FD726',
    },
    {
      label: 'Product Bug', count: productBug, percentage: `${((productBug / totalFailures) * 100).toFixed(2)}%`, color: '#FF0000',
    },
    {
      label: 'No Defect', count: noDefect, percentage: `${((noDefect / totalFailures) * 100).toFixed(2)}%`, color: '#1D6AE5',
    },
  ];

  const transformedData = useMemo(() => (
    buildSummary && Object.keys(buildSummary)?.map((key) => ({
      label: key,
      val: buildSummary[key],
    }))
  ), [buildSummary]);

  const getChipClass = (label) => {
    switch (label) {
      case 'passed':
        return classes.chipSuccess;
      case 'failed':
        return classes.chipFailure;
      case 'skipped':
        return classes.chipSkipped;
      case 'ignored':
      case 'pending':
      case 'flaky':
        return classes.chipIgnored;
      default:
        return classes.chipLabel;
    }
  };

  const getReportData = async () => {
    try {
      setLoading(true);
      let res;
      switch (reportType) {
        case FileType.SELENIUM:
        case FileType.XML:
          res = await resultListById(reportId);
          setChartData(...segregateSeleniumData([res.data]));
          setBuildSummary(extractBuildSummaryXML(res.data));
          setTestReport(res?.data?.testingResults['testng-results']);
          break;
        case FileType.HTML:
          res = await resultListHTMLById(reportId);
          setChartData(...segregateSeleniumHTMLData([res.data]));
          setBuildSummary(extractBuildSummaryHTML(res.data));
          setTestReport(res?.data?.testingResults);
          break;
        case FileType.PLAYWRIGHT:
          res = await playwrightResultListById(reportId);
          setChartData(...segregatePlaywrightData([res.data]));
          setBuildSummary(extractBuildSummaryPlaywright(res.data));
          setTestReport({
            reportResult: res?.data?.reportResult,
            staticsResult: res?.data?.staticsResult,
          });
          break;
        case FileType.CYPRESS:
          res = await cypressResultListById(reportId);
          setChartData(...segregateCypressData([res.data]));
          setBuildSummary(extractBuildSummaryCypress(res.data));
          setTestReport(res?.data?.reportResult?.dataRow?.results);
          break;
        default:
          throw new Error('Unsupported report type');
      }
      setReportData({
        reportName: res?.data?.testReportName,
        companyId: res?.data?.companyId,
        projectId: res?.data?.projectId,
      });
    } catch (error) {
      navigate(`/${route.ROUTE_HOME_PAGE}`, { state: { status: true, error: `${error?.data?.message || error?.message}` } });
    } finally {
      setLoading(false);
    }
  };

  const getTagCount = async () => {
    try {
      const res = await commentService.getTagsForGraph(reportId);
      setTagsCount(res);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllTags = (id) => {
    // setLoading(true);
    commentService.getTagNamebyReportId(id).then((res) => {
      if (Object.keys(res).length) {
        setTags(res);
      } else {
        console.error('Invalid response format:', res);
      }
      setLoading(false);
    }).catch((error) => {
      console.error('Error loading comments:', error);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (reportType && reportId) {
      getReportData();
      getTagCount();
    }
  }, [reportId, reportType]);

  useEffect(() => {
    if (reportType && reportId) {
      getTagCount();
    }
  }, [reportId, reportType, buildRunTab]);

  useEffect(() => {
    if (buildRunTab && reportId) getAllTags(reportId);
  }, [buildRunTab, openTest, bugTab]);

  const getCummilativeData = async () => {
    try {
      setLoading(true);
      let res;
      switch (reportType) {
        case FileType.SELENIUM:
        case FileType.XML:
          res = await await reportService.getCummilitiveBuildRunData(reportData);
          break;
        case FileType.HTML:
          res = await reportService.getCummilitiveHTMLBuildRunData(reportData);
          break;
        case FileType.PLAYWRIGHT:
          res = await reportService.getCummilitivePlayBuildRunData(reportData);
          break;
        case FileType.CYPRESS:
          res = await reportService.getCummilitiveCypressBuildRunData(reportData);
          break;
        default:
          throw new Error('Unsupported report type');
      }
      setBuildHistory(res.reportByNameResponses);
      setBuildRunData(res.buildRunDataResponse);
      setBuildStability(res.buildStabilityGraphResponse);
      setUniqueErrors(res.uniqueErrorResponse);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (reportType && reportData.projectId && reportData.companyId) {
      getCummilativeData();
    }
    setReportType(reportType);
  }, [reportType, reportData]);

  useEffect(() => {
    if (user?.role?.roleName !== UserRole.SUPER_ADMIN) {
      setActiveItem(!['SELENIUM', 'PLAY_WRIGHT', 'CYPRESS', 'Home'].includes(activeItem) ? 'Dashboard' : 'Home');
    } else {
      setActiveItem([FileType.SELENIUM, FileType.XML, FileType.HTML].includes(reportType)
        ? FileType.SELENIUM
        : reportType);
    }
  }, [user]);

  const handleRedirect = () => {
    if (user?.role?.roleName !== UserRole.SUPER_ADMIN) {
      setActiveItem(!['SELENIUM', 'PLAY_WRIGHT', 'CYPRESS', 'Home'].includes(activeItem) ? 'Dashboard' : 'Home');
    } else {
      setActiveItem([FileType.SELENIUM, FileType.XML, FileType.HTML].includes(reportType)
        ? FileType.SELENIUM
        : reportType);
    }
    navigate(`/${!['SELENIUM', 'PLAY_WRIGHT', 'CYPRESS', 'Home'].includes(activeItem) ? route.ROUTE_DASHBOARD : route.ROUTE_HOME_PAGE}`);
  };

  useMeta(chartData?.testReportName, '');

  if (!chartData || downloading) {
    return <Loader />;
  }

  return (
    <div ref={targetRef}>
      <Box ref={headerRef} className={classes.appBar} sx={{ display: 'none' }}>
        <div className={classes.logo}>
          <img src={Logo} alt="Logo" className={classes.logoImage} />
        </div>
      </Box>
      <Container maxWidth={false} className={classes.root}>
        <CssBaseline />
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" marginBottom={2}>
          <Typography
            color="#666666"
            onClick={handleRedirect}
            sx={{ cursor: 'pointer' }}
          >
            {!['SELENIUM', 'PLAY_WRIGHT', 'CYPRESS', 'Home'].includes(activeItem) ? 'Dashboard' : 'Home'}
          </Typography>
          <Typography color="#666666">{TabLabels[buildRunTab]}</Typography>
        </Breadcrumbs>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h4">
            Build Run
            <Typography fontWeight="400" variant="h6">{chartData?.testReportName}</Typography>
          </Typography>
          <Button ref={downloadRef} disabled={!chartData || loading || downloading} className={classes.downloadIcon} variant="text" size="small">
            {openTest ? <CloseIcon onClick={() => setOpenTest(false)} />
              : <Tooltip title={`${TabLabels[buildRunTab]} download`}>
                <DownloadIcon onClick={generatePDF} />
              </Tooltip>}
          </Button>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          bgcolor="#f5f5f5"
          borderRadius={2}
          gap={1}
          mb={2}
        >
          <Chip
            icon={chartData.failed ? <img style={{ width: '18px', height: '18px' }} src={CancelIcon} alt="cancel-icon" /> : <img style={{ width: '18px', height: '18px' }} src={CheckIcon} alt="check-icon" />}
            label={chartData.failed ? 'Failed' : 'Passed'}
            className={`${chartData && chartData?.failed ? classes.chipFailed : classes.chipPassed}`}
          />
          <Box display="flex" alignItems="center">
            <img width={18} height={18} src={UserCircleIcon} alt="user" />
            <Typography className={classes.subHeader} variant="body2" color mx={1}>
              {chartData?.customUserResponse?.username}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <img width={18} height={18} src={ClockIcon} alt="clock" />
            <Typography className={classes.subHeader} variant="body2" mx={1}>
              {chartData?.startedAt}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <img width={18} height={18} src={stopwatchIcon} alt="stopwatch" />
            <Typography className={classes.subHeader} variant="body2" mx={1}>
              {chartData?.duration}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {Object.entries(chartData?.platformNameAndBrowserName || {}).length > 0
              ? Object.entries(chartData.platformNameAndBrowserName).map(([platform, browser]) => (
                <>
                  <Tooltip title={toTitleCase(platform)}>
                    <Avatar
                      src={plateformIcons[platform?.toLowerCase()]}
                      sx={{ width: 18, height: 18, borderRadius: 0 }}
                    />
                  </Tooltip>
                  <Tooltip title={toTitleCase(browser)}>
                    <Avatar
                      src={browserIcons[browser?.toLowerCase()]}
                      sx={{ width: 18, height: 18, borderRadius: 0 }}
                    />
                  </Tooltip>
                </>
              ))
              : Object.entries({
                [platformEnum.WINDOWS]: browserEnum.CHROME,
              }).map(([platform, browser]) => (
                <>
                  <Tooltip title={toTitleCase(platform)}>
                    <Avatar
                      src={plateformIcons[platform.toLowerCase()]}
                      sx={{ width: 18, height: 18, borderRadius: 0 }}
                    />
                  </Tooltip>
                  <Tooltip title={toTitleCase(browser)}>
                    <Avatar
                      src={browserIcons[browser.toLowerCase()]}
                      sx={{ width: 18, height: 18, borderRadius: 0 }}
                    />
                  </Tooltip>
                </>
              ))}
          </Box>
          {chartData?.jenkinsBuildVersion !== null && <Box sx={{ ml: 2 }}>
            <Tooltip title="Build Version">
              <Badge max={Number.MAX_VALUE} badgeContent={chartData?.jenkinsBuildVersion} color="primary" sx={{ marginLeft: '10px', '& .MuiBadge-badge': { maxHeight: '15px' } }}>
                <img src={jenkinsImg} alt="build-version" height={25} />
              </Badge>
            </Tooltip>
          </Box>}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: selectedTests?.id ? 'calc(50% - 16px)' : '100%' }}>
          <Tabs
            value={buildRunTab}
            onChange={handleChange}
            indicatorColor="none"
            className={classes.tabsMain}
          >
            {TabLabels?.map((item, index) => (
              <Tab
                key={index}
                className={buildRunTab === index ? classes.balloonInActive : classes.balloon}
                label={item}
              />
            ))}
          </Tabs>
          {!buildRunTab && <Box className={classes.chips}>
            {transformedData && transformedData?.map(({ label, val }) => {
              if (label === 'total') {
                return null;
              }
              const chipClass = getChipClass(label);
              const tooltipTitle = chipEnum[label];

              return (
                <Tooltip title={tooltipTitle} key={label}>
                  <Chip
                    variant="outlined"
                    label={val}
                    className={chipClass}
                    onClick={val > 0 ? () => handleChipClick(label) : undefined}
                  />
                </Tooltip>
              );
            })}
          </Box>}
          {!!selectedTests?.id
          && ([TestStatus.FAIL, PlatwrightTestStatus.FAILED].includes(selectedTests?.test?.status)
            || selectedTests?.test?.state === CypressTestStatus.FAILED
            || selectedTests?.test?.testMethod?.[0]?.status === HtmlStatus.FAILED)
            && <Box display="flex" justifyContent="flex-end">
              <Box position="relative">
                <Button
                  color="primary"
                  disableRipple
                  disableTouchRipple
                  disabled={!chartData}
                  onClick={handleAssistant}
                  startIcon={<img src={ChatIcon} alt="icon" style={{ maxWidth: 120, maxHeight: 28 }} />}
                  sx={{
                    padding: 0,
                    minWidth: '120px',
                    minHeight: '28px',
                    ml: 2,
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.1)',
                    },
                  }}
                />
              </Box>
            </Box>}
        </Box>
        {buildRunTab === 0 && (
          <>
            <Grid container spacing={2}>
              <Grid item sm={12} md={4}>
                <Paper className={classes.summaryBox}>
                  <Grid className={classes.summaryHeading}>
                    <Typography fontWeight="bold" variant="body1">Build Summary</Typography>
                  </Grid>
                  <Box sx={{ overflow: 'hidden' }} className={classes.summaryGraphBoxIn}>
                    <BuildChart
                      data={buildSummary}
                      reportType={reportType}
                      loading={loading}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item sm={12} md={4}>
                <Paper className={`${classes.summaryBox} highchart-box`}>
                  <Grid className={classes.summaryHeading}>
                    <Typography fontWeight="bold" variant="body1">Build History</Typography>
                  </Grid>
                  <Box className={classes.summaryGraphBoxIn}>
                    <HorizontalStackedBarChart
                      data={buildHistory}
                      reportType={reportType}
                      loading={loading}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item sm={12} md={4}>
                <Paper className={classes.summaryBox}>
                  <Grid className={classes.summaryHeading}>
                    <Typography fontWeight="bold" variant="body1">Build Stability</Typography>
                  </Grid>
                  <Box className={classes.summaryGraphBoxIn}>
                    <LineChartWithStats data={buildStability} loading={loading} />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box className={classes.testResultSummary}>
                  {/* Flakiness */}
                  <Paper className={classes.summaryBox}>
                    <Grid className={classes.summaryHeading}>
                      <Typography fontWeight="bold" variant="body1">Flakiness</Typography>
                    </Grid>
                    <Box className={classes.summaryBoxIn}>
                      <Box className={classes.metric}>
                        {loading ? <>
                          <Skeleton variant="text" animation="wave" sx={{ minWidth: '50px' }} />
                        </> : <Typography variant="h5" className={classes.metricValue}>{buildRunData?.flakiness}</Typography>}
                      </Box>
                    </Box>
                  </Paper>

                  {/* Always Failing */}
                  <Paper className={classes.summaryBox}>
                    <Grid className={classes.summaryHeading}>
                      <Typography fontWeight="bold" variant="body1">Always Failing</Typography>
                    </Grid>
                    <Box className={classes.summaryBoxIn}>
                      <Box className={classes.metric}>
                        {loading ? <>
                          <Skeleton variant="text" animation="wave" sx={{ minWidth: '50px' }} />
                        </> : <Typography variant="h5" className={classes.metricValue}>{buildRunData?.alwaysFailing}</Typography>}
                      </Box>
                    </Box>
                  </Paper>

                  {/* Muted Tests */}
                  <Paper className={classes.summaryBox}>
                    <Grid className={classes.summaryHeading}>
                      <Typography fontWeight="bold" variant="body1">Muted Tests</Typography>
                    </Grid>
                    <Box className={classes.summaryBoxIn}>
                      <Box className={classes.metric}>
                        {loading ? <>
                          <Skeleton variant="text" animation="wave" sx={{ minWidth: '50px' }} />
                        </> : <Typography variant="h5" className={classes.metricValue}>{buildRunData?.mutedTest}</Typography>}
                      </Box>
                    </Box>
                  </Paper>

                  {/* New Failures */}
                  <Paper className={classes.summaryBox}>
                    <Grid className={classes.summaryHeading}>
                      <Typography fontWeight="bold" variant="body1">New Failures</Typography>
                    </Grid>
                    <Box className={classes.summaryBoxIn}>
                      <Box className={classes.metric}>
                        {loading ? <>
                          <Skeleton variant="text" animation="wave" sx={{ minWidth: '50px' }} />
                        </> : <Typography variant="h5" className={classes.metricValue}>{buildRunData?.newFailure}</Typography>}
                      </Box>
                    </Box>
                  </Paper>
                </Box>

                {/* Failure Categories */}
                <Paper sx={{ mt: 4 }} className={classes.summaryBox}>
                  <Grid className={classes.summaryHeading}>
                    <Typography fontWeight="bold" variant="body1">Failure Categories</Typography>
                  </Grid>
                  <Box className={classes.summaryBoxIn}>
                    <Box sx={{ width: '80%' }}>
                      {categories && categories?.map((category, index) => (
                        <Box key={index.id} className={classes.category}>
                          <Box display="flex" alignItems="center">
                            <Box
                              className={classes.categoryColor}
                              style={{ backgroundColor: category.color }}
                            />
                            <Typography variant="body1" className={classes.metricTitle}>{category?.label}</Typography>
                          </Box>
                          <Typography variant="body1" className={classes.categoryCount}>
                            {category?.count}
                            {category?.percentage && `(${category?.percentage})`}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box className={classes.totalFailures}>
                      <Box className={classes.verticalBar}>
                        <Tooltip title={`To Be Investigated ${totalFailures > 0 ? toInvestigate || buildSummary?.failed : ''}`}>
                          <Box
                            className={totalFailures === toInvestigate
                              ? classes.barSegmentNonActive
                              : classes.barSegment}
                            style={{
                              height: toInvestigate === 0
                                && productBug === 0
                                && automationBug === 0
                                && noDefect === 0
                                && environmentIssue === 0
                                ? '100%'
                                : `${((toInvestigate / totalFailures) * 100)}%`,
                              backgroundColor: '#D9D9D9',
                            }}
                          />
                        </Tooltip>
                        {productBug > 0 && <Tooltip title={`Production Bug ${totalFailures > 0 ? productBug : ''}`}>
                          <Box
                            className={productBug > 0
                              ? classes.barSegment
                              : classes.barSegmentNonActive}
                            style={{
                              height: totalFailures > 0 ? `${((productBug / totalFailures) * 100)}%` : '',
                              backgroundColor: '#FF0000',
                            }}
                          />
                        </Tooltip>}
                        {automationBug > 0 && <Tooltip title={`Automation Bug ${totalFailures > 0 ? automationBug : ''}`}>
                          <Box
                            className={automationBug > 0
                              ? classes.barSegment
                              : classes.barSegmentNonActive}
                            style={{
                              height: totalFailures > 0 ? `${((automationBug / totalFailures) * 100)}%` : '',
                              backgroundColor: '#FFC72B',
                            }}
                          />
                        </Tooltip>}
                        {noDefect > 0 && <Tooltip title={`No Defect ${totalFailures > 0 ? noDefect : ''}`}>
                          <Box
                            className={noDefect > 0
                              ? classes.barSegment
                              : classes.barSegmentNonActive}
                            style={{
                              height: totalFailures > 0 ? `${((noDefect / totalFailures) * 100)}%` : '',
                              backgroundColor: '#1D6AE5',
                            }}
                          />
                        </Tooltip>}
                        {environmentIssue > 0 && <Tooltip title={`Environment Issue ${totalFailures > 0 ? environmentIssue : ''}`}>
                          <Box
                            className={environmentIssue > 0
                              ? classes.barSegment
                              : classes.barSegmentNonActive}
                            style={{
                              height: totalFailures > 0 ? `${((environmentIssue / totalFailures) * 100)}%` : '',
                              backgroundColor: '#5FD726',
                            }}
                          />
                        </Tooltip>}
                      </Box>
                      <Typography fontWeight="bold" variant="h6">{buildSummary?.failed || 0}</Typography>
                      <Typography variant="body1" className={classes.metricTitle}>Failures</Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              {/* Right Section */}
              <Grid item xs={12} md={8}>
                {/* Unique Errors */}
                <Paper className={classes.summaryBoxUnique} sx={{ height: 675, overflow: 'auto' }}>
                  <Grid className={classes.summaryHeading}>
                    <Typography fontWeight="bold" variant="body1">Unique Errors</Typography>
                  </Grid>
                  <Box className={classes.summaryBoxIn}>
                    <Box className={classes.metric}>
                      <Typography fontWeight="bold" variant="body1" my={1}>Total</Typography>
                      <Box display="flex" alignItems="baseline" mb={1}>
                        <Typography fontWeight="bold" variant="h6">{uniqueErrors?.totalErrors}</Typography>
                        {uniqueErrors && <Typography variant="body2" className={classes.metricTitle} ml={1}>
                          {`${uniqueErrors?.failurePercent}% failures caused by ${uniqueErrors?.uniqueErrors?.length} unique errors`}
                        </Typography>}
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ width: '100%', overflow: 'auto' }}>
                    {loading ? <>
                      <Box display="flex" flexDirection="column" gap={3}>
                        <Skeleton variant="rounded" />
                        <Skeleton variant="rounded" />
                        <Skeleton variant="rounded" />
                        <Skeleton variant="rounded" />
                        <Skeleton variant="rounded" />
                        <Skeleton variant="rounded" />
                      </Box>
                    </> : <>
                      {uniqueErrors && uniqueErrors?.uniqueErrors?.map((error, index) => (
                        <Box className={classes.collapseBox} key={index.id} mb={2}>
                          <Box sx={{ width: '100%' }} onClick={() => handleToggle(index)}>
                            <Box display="flex" justifyContent="space-between">
                              <Box display="flex" alignItems="center">
                                <Box className={classes.errorIcon}>
                                  <Typography fontWeight="bold">{error?.count}</Typography>
                                </Box>
                                <Typography>{error?.exceptionClass}</Typography>
                              </Box>
                              <IconButton>
                                <ExpandMoreIcon />
                              </IconButton>
                            </Box>
                            <Collapse sx={{ ml: 5, maxWidth: '100%' }} in={expanded === index}>
                              <Typography sx={{ wordBreak: 'break-word' }}>{error?.message}</Typography>
                            </Collapse>
                          </Box>
                        </Box>
                      ))}
                    </>}
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                {/* Run Summary */}
                <Paper className={classes.summaryBox}>
                  <Grid className={classes.summaryHeading}>
                    <Typography fontWeight="bold" variant="body1">Run Summary</Typography>
                  </Grid>
                  <Box className={classes.summaryBoxIn}>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                      <Table>
                        <TableHead>
                          <TableRow className={classes.tableHeader}>
                            <TableCell sx={{ width: '50%' }} key="runs" className={classes.headerCell}>
                              Runs
                            </TableCell>
                            {transformedData && transformedData?.map(({ label }) => (
                              <TableCell key={label} className={classes.headerCell}>
                                {label.charAt(0).toUpperCase() + label.slice(1)}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {loading ? <>
                          <TableRow>
                            <TableCell><Skeleton animation="wave" /></TableCell>
                            <TableCell><Skeleton animation="wave" /></TableCell>
                            <TableCell><Skeleton animation="wave" /></TableCell>
                            <TableCell><Skeleton animation="wave" /></TableCell>
                            <TableCell><Skeleton animation="wave" /></TableCell>
                            <TableCell><Skeleton animation="wave" /></TableCell>
                          </TableRow>
                        </> : <TableBody>
                          <TableCell sx={{ width: '50%' }} key="total">
                            <Typography variant="body2" color="textSecondary">
                              Total
                            </Typography>
                          </TableCell>
                          {transformedData && transformedData?.map(({ val }, index) => (
                            <TableCell key={index}>
                              <Typography variant="body2" color="textSecondary">
                                {val}
                              </Typography>
                            </TableCell>
                          ))}
                        </TableBody>}
                      </Table>
                    </TableContainer>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
        {buildRunTab === 1 && testReport && (
          <Box>
            <TestReportList
              chipsData={transformedData}
              bugTab={bugTab}
              setBugTab={setBugTab}
              openTest={openTest}
              setOpenTest={setOpenTest}
              tags={tags}
              chartData={chartData}
              testData={testReport}
              reportType={reportType}
              selectedTests={selectedTests}
              setSelectedTests={setSelectedTests}
              imageBlob={imageBlob}
              setAnalyzing={setAnalyzing}
              setImageBlob={setImageBlob}
            />
          </Box>
        )}
      </Container>
      <AnalyzingLoader open={analyzing} />
      {
        snackbar.state && (
          <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
        )
      }
    </div>
  );
};

export default BuildRun;
