/* eslint-disable react/no-this-in-sfc */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  TextField,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { createJiraProject } from '../../services/integrations';
import route from '../../utils/route';
import commonUtils from '../../utils/common/common';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
    padding: '20px',
  },
  '& .MuiDialogTitle-root': {
    fontWeight: 600,
    fontSize: '24px',
  },
  '& .MuiDialogContent-root': {
    paddingTop: '20px !important',
  },
}));

const JiraIntegrationModal = ({
  open, handleClose, companyId, companyDropdown, projects, setCompanyId,
}) => {
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const initialValues = {
    accountApiKey: '',
    jiraAccountDomainName: '',
    jiraAccountEmail: '',
    key: '',
    leadAccountId: '',
    projectName: '',
    projectId: '',
    companyId: companyId || '',
  };
  Yup.addMethod(Yup.string, 'sanitizeInput', function sanitizeInput() {
    return this.transform((value) => commonUtils.sanitizeInput(value));
  });
  const validationSchema = Yup.object({
    accountApiKey: Yup.string().sanitizeInput().required('Account API Key is required'),
    jiraAccountDomainName: Yup.string().required('Jira Account Domain Name is required'),
    jiraAccountEmail: Yup.string()
      .email('Invalid email format')
      .required('Jira Account Email is required'),
    key: Yup.string().sanitizeInput()
      .max(10, 'Key cannot be longer than 10 characters')
      .required('Key is required'),
    leadAccountId: Yup.string().sanitizeInput().required('Lead Account ID is required'),
    projectId: Yup.string().required('Project is required'),
  });

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await createJiraProject({
        ...values,
        projectName: projects.find((project) => project.projectId === values.projectId).projectName,
      });
      resetForm();
      navigate(`/${route.ROUTE_PERSONALINFO}`, { state: { showJira: true, message: 'Jira Integration Successful' } });
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.data.message}`,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="jira-integration-dialog-title"
    >
      <DialogTitle id="jira-integration-dialog-title">
        Jira Integration
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, handleChange }) => (
            <Form>
              <Field
                as={TextField}
                select
                fullWidth
                label="Company"
                name="companyId"
                variant="outlined"
                margin="normal"
                value={companyId}
                onChange={(e) => {
                  setCompanyId(e.target.value);
                  handleChange(e);
                }}
                InputLabelProps={{
                  shrink: companyId !== null,
                }}
                helperText={<ErrorMessage name="companyId" />}
              >
                {companyDropdown.length > 0 ? (
                  companyDropdown.map((company) => (
                    <MenuItem key={company.companyId} value={company.companyId}>
                      {company.companyName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Companies Available</MenuItem>
                )}
              </Field>
              <Field
                name="projectId"
                as={TextField}
                select
                fullWidth
                label="Project"
                variant="outlined"
                margin="normal"
                helperText={<ErrorMessage name="projectId" />}
              >
                {projects.length > 0 ? (
                  projects.map((project) => (
                    <MenuItem key={project.projectId} value={project.projectId}>
                      {project.projectName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Projects Available</MenuItem>
                )}
              </Field>
              <Field
                name="accountApiKey"
                as={TextField}
                fullWidth
                label="Account API Key"
                variant="outlined"
                margin="normal"
                helperText={<ErrorMessage name="accountApiKey" />}
              />
              <Field
                name="jiraAccountDomainName"
                as={TextField}
                fullWidth
                label="Jira Domain Name"
                variant="outlined"
                margin="normal"
                helperText={<ErrorMessage name="jiraAccountDomainName" />}
              />
              <Field
                name="jiraAccountEmail"
                as={TextField}
                fullWidth
                label="Jira Email"
                variant="outlined"
                margin="normal"
                helperText={<ErrorMessage name="jiraAccountEmail" />}
              />
              <Field
                name="key"
                as={TextField}
                fullWidth
                label="Key"
                variant="outlined"
                margin="normal"
                helperText={<ErrorMessage name="key" />}
              />
              <Field
                name="leadAccountId"
                as={TextField}
                fullWidth
                label="Lead Account ID"
                variant="outlined"
                margin="normal"
                helperText={<ErrorMessage name="leadAccountId" />}
              />
              <DialogActions>
                <Button onClick={handleClose} color="warning">
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
      {snackbar.state && (
        <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
      )}
    </StyledDialog>
  );
};

JiraIntegrationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  companyDropdown: [],
  projects: [],
  companyId: PropTypes.string,
  setCompanyId: PropTypes.func,
};
JiraIntegrationModal.defaultProps = {
  companyDropdown: [],
  projects: [],
  companyId: PropTypes.string,
  setCompanyId: () => { },
};

export default JiraIntegrationModal;
