import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
    padding: '20px',
    width: '500px',
  },
  '& .MuiDialogTitle-root': {
    fontWeight: 600,
    fontSize: '24px',
  },
  '& .MuiDialogContent-root': {
    paddingTop: '20px !important',
  },
}));

const IntegrationModal = ({
  open, handleClose, onConfirm,
  companyId, companyDropdown, projects, setCompanyId,
}) => {
  const [slackUrl, setSlackUrl] = useState('');
  const [projectId, setProjectId] = useState('');
  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>
        Slack Integration
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '20px' }}>
        <>
          {/* Company Dropdown */}
          <FormControl sx={{ width: '100%', marginBottom: '16px' }}>
            <InputLabel size="small">Company</InputLabel>
            <Select
              value={companyId}
              onChange={(e) => {
                setCompanyId(e.target.value);
              }}
              size="small"
              label="Company"
            >
              {companyDropdown.length > 0 ? (
                companyDropdown.map((company) => (
                  <MenuItem key={company.companyId} value={company.companyId}>
                    {company.companyName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Companies Available</MenuItem>
              )}
            </Select>
          </FormControl>

          {/* Projects Dropdown */}
          <FormControl sx={{ width: '100%', marginBottom: '16px' }}>
            <InputLabel size="small">Project</InputLabel>
            <Select
              value={projectId}
              onChange={(e) => setProjectId(e.target.value)}
              size="small"
              label="Project"
            >
              {projects.length > 0 ? (
                projects.map((project) => (
                  <MenuItem key={project.projectId} value={project.projectId}>
                    {project.projectName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Projects Available</MenuItem>
              )}
            </Select>
          </FormControl>
          {/* Slack Web Hook URL Input */}
          <FormControl sx={{ width: '100%', marginBottom: '16px' }}>
            <InputLabel size="small">Slack Web Hook Url</InputLabel>
            <Input
              value={slackUrl}
              onChange={(e) => setSlackUrl(e.target.value)}
              label="Slack Web Hook Url"
            />
          </FormControl>
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="warning">
          Cancel
        </Button>
        <Button disabled={!companyId || !projectId || !slackUrl} onClick={() => onConfirm({ slackUrl, projectId, companyId })} color="primary">
          Submit
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

IntegrationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  companyDropdown: [],
  projects: [],
  companyId: PropTypes.string,
  setCompanyId: PropTypes.func,
};
IntegrationModal.defaultProps = {
  companyDropdown: [],
  projects: [],
  companyId: PropTypes.string,
  setCompanyId: () => { },
};

export default IntegrationModal;
