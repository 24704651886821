/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
// import SendIcon from '@mui/icons-material/Send';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import aiAssistant from '../services/openApiServices';
import CommonUtils from '../utils/common/common';

const { markdown } = require('markdown');

const useStyles = makeStyles((theme) => ({
  markdownContent: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    lineHeight: 1.6,
    '& h3': {
      fontSize: '1.2rem',
      margin: theme.spacing(2, 0),
    },
    '& h4': {
      fontSize: '1rem',
      margin: theme.spacing(2, 0),
    },
    '& p': {
      marginBottom: theme.spacing(1.5),
      fontSize: '1rem',
    },
    '& strong': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.secondary,
    },
    '& hr': {
      border: 'none',
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(2, 0),
    },
    '& ul, & ol': {
      margin: theme.spacing(1, 0),
      paddingLeft: theme.spacing(3),
    },
    '& li': {
      listStyle: 'disc',
      marginBottom: theme.spacing(0.5),
      fontSize: '0.95rem',
    },
  },
}));

export default function ChatGPTAssistant({
  imageBlob, setAnalyzing, exception, reportId, testName,
}) {
  const classes = useStyles();
  const dialogContentRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);

  const uploadImage = async () => {
    if (!imageBlob) return;

    try {
      setAnalyzing(true);
      const reportName = CommonUtils.replaceAmpersandWithAnd(testName);
      const reportData = { name: reportName, testReportId: reportId };
      const res = await aiAssistant(imageBlob, exception, reportData);
      const messageContent = res.choices[0]?.message?.content || 'No content available';
      const cleanContent = messageContent.replace(/```html|```/g, '');
      setMessages((prev) => [
        ...prev,
        { role: 'assistant', content: cleanContent, timestamp: new Date() },
      ]);
      setOpen(true);
    } catch (error) {
      console.error(error);
      setMessages((prev) => [
        ...prev,
        { role: 'assistant', content: 'Error uploading image.', timestamp: new Date() },
      ]);
    } finally {
      setAnalyzing(false);
    }
  };

  useEffect(() => {
    if (imageBlob && messages.length === 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        setMessages((prev) => [
          ...prev,
          { role: 'user', content: base64Image, timestamp: new Date() },
        ]);

        uploadImage();
      };
      reader.readAsDataURL(imageBlob);
    } else if (messages.length > 0) {
      setOpen(true);
      setAnalyzing(false);
    }
  }, [imageBlob]);

  useEffect(() => {
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [open, dialogContentRef.current]);

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        PaperProps={{
          style: {
            marginTop: '64px',
            maxWidth: '50%',
            borderRadius: '8px',
            boxShadow: '-4px 4px 0px #FFC72B,1px 1px 3px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <DialogTitle sx={{
          borderBottom: '1px solid #E1E1E1',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
        >
          AI Analysis
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            height: '100%',
            maxHeight: 'calc(100vh - 10%)',
            overflowY: 'hidden',
            padding: '16px',
            wordBreak: 'break-word',
          }}
        >
          <div
            ref={dialogContentRef}
            style={{
              height: '100%',
              overflowY: 'auto',
              padding: '16px',
            }}
          >
            <List className="ai-assistant-child">
              {messages?.map((msg, index) => (
                <ListItem key={index} style={{ display: 'flex', alignItems: 'flex-start' }}>
                  {msg.role === 'assistant' && (
                    <ListItemAvatar>
                      <Avatar src="/assistant-avatar.png" alt="Assistant" />
                    </ListItemAvatar>
                  )}
                  <Paper
                    style={{
                      backgroundColor: msg.role === 'user' ? '#F3EDDE' : '#f5f5f9',
                      padding: '8px 12px',
                      borderRadius: '8px',
                      margin: '5px 0 5px auto',
                      maxWidth: msg.content.startsWith('data:image/') ? '50%' : '100%',
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          {msg.content.startsWith('data:image/') ? (
                            <img src={msg.content} alt="Message Content" style={{ maxWidth: '100%', borderRadius: '8px', height: '200px' }} />
                          ) : (
                            <div
                              className={classes.markdownContent}
                              dangerouslySetInnerHTML={{ __html: markdown.toHTML(msg.content) }}
                            />
                          )}
                        </>
                      }
                      secondary={
                        <Typography variant="caption" color="textSecondary">
                          {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        </Typography>
                      }
                    />
                  </Paper>
                  {msg.role === 'user' && (
                    <ListItemAvatar>
                      <Avatar src="/user-avatar.png" alt="You" />
                    </ListItemAvatar>
                  )}
                </ListItem>
              ))}
            </List>
          </div>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

ChatGPTAssistant.propTypes = {
  exception: PropTypes.string,
  imageBlob: PropTypes.string,
  setAnalyzing: PropTypes.func,
  reportId: PropTypes.string,
  testName: PropTypes.string,
};

ChatGPTAssistant.defaultProps = {
  exception: '',
  imageBlob: '',
  setAnalyzing: () => { },
  reportId: '',
  testName: '',
};
