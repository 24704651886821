import PropTypes from 'prop-types';
import React from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const ReCaptcha = ({ onVerify, refreshReCaptcha, action }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_SITE}
    useRecaptchaNet
    useEnterprise
    scriptProps={{
      async: true,
      defer: true,
      appendTo: 'head',
      nonce: undefined,
    }}
    container={{
      element: 'google-recaptcha',
      parameters: {
        badge: 'bottomright',
        theme: 'light',
      },
    }}
  >
    <GoogleReCaptcha
      onVerify={onVerify}
      refreshReCaptcha={refreshReCaptcha}
      action={action}
    />
  </GoogleReCaptchaProvider>
);

export default ReCaptcha;

ReCaptcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
  refreshReCaptcha: PropTypes.bool.isRequired,
  action: PropTypes.string,
};
ReCaptcha.defaultProps = {
  action: '',
};
