/* eslint-disable import/no-extraneous-dependencies */
import sanitize from 'sanitize-html';
import disposableEmailProviders from '../disposable_email_blocklist';

const validateEmail = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const validatePassword = RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[a-z]).{10,}$/);
const validName = RegExp(/^[a-zA-Z '.-]*$/);
const validatePhone = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function isDisposableEmail(value) {
  const emailDomain = value && value.split('@')[1];
  if (emailDomain && !disposableEmailProviders.includes(emailDomain)) return false;
  return true;
}
function isValidReport(report) {
  const isValid = !!report && !!report.testingResults && report?.testingResults['testng-results'] && report?.testingResults['testng-results']?.suite;
  return isValid;
}

function isValidHtmlReport(report) {
  const isValid = !!report && !!report.testingResults && report?.testingResults?.suite;
  return isValid;
}

function isValidPlaywrightReport(report) {
  const isValid = !!report && !!report.reportResult && report?.reportResult.files[0].tests.length;
  return isValid;
}

function isValidCypressReport(report) {
  const isValid = !!report && !!report.reportResult
    && report?.reportResult?.dataRow?.results.length;
  return isValid;
}
function isValidReportResonse(response) {
  return !!response && response?.content && Array.isArray(response.content);
}

function replaceAmpersandWithAnd(inputString) {
  return inputString.replace(/&/g, 'and');
}

function passwordValidate(password) {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
  return passwordRegex.test(password);
}

// HTML Sanitization
const sanitizeOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'p', 'br', 'ul', 'ol', 'li'],

  allowedAttributes: {},
  disallowedTagsMode: 'completelyDiscard',

  transformTags: {
    '*': (tagName, attribs) => {
      const sanitizedAttribs = { ...attribs };

      Object.keys(sanitizedAttribs).forEach((key) => {
        if (key.startsWith('on')) {
          delete sanitizedAttribs[key];
        }
      });

      return { tagName, attribs: sanitizedAttribs };
    },
  },
  allowedSchemes: [],
  allowedSchemesByTag: {
    img: [],
    a: [],
  },

  parseStyleAttributes: false,
};

function containsHtml(str) {
  return /<\/?[a-z][\s\S]*>/i.test(str);
}

function containsUrl(str) {
  const urlPattern = /https?:\/\/[^\s]+|ftp:\/\/[^\s]+|data:[^\s]+/g;
  return urlPattern.test(str);
}

function containsEncodedChars(str) {
  const encodedPattern = /%[0-9A-Fa-f]{2}/g;
  return encodedPattern.test(str);
}

function containsBase64(str) {
  const base64Pattern = /(?:[A-Za-z0-9+/=]{4}){2,}/g;
  return base64Pattern.test(str);
}

function containsMaliciousHeaders(str) {
  const maliciousHeaderPattern = /(?:\bCookie:|\bHost:|\bContent-Type:|\bTransfer-Encoding:|\bAuthorization:|%0D%0A)/i;
  return maliciousHeaderPattern.test(str);
}

function containsSqlInjection(str) {
  const sqlPattern = /(\b(SELECT|INSERT|UPDATE|DELETE|DROP|CREATE|ALTER|TRUNCATE)\b|--|\bOR\b\s1=1|#|\bUNION\b|\bEXEC\b)/i;
  return sqlPattern.test(str);
}

function containsCommandInjection(str) {
  const cmdInjectionPattern = /[|;&`]/g;
  return cmdInjectionPattern.test(str);
}

function containsPathTraversal(str) {
  const pathTraversalPattern = /(\.\.\/|\.\.\\)/g;
  return pathTraversalPattern.test(str);
}

function sanitizeInput(dirtyInput) {
  if (containsHtml(dirtyInput)
    || containsUrl(dirtyInput)
    || containsEncodedChars(dirtyInput)
    || containsBase64(dirtyInput)
    || containsMaliciousHeaders(dirtyInput)
    || containsSqlInjection(dirtyInput)
    || containsCommandInjection(dirtyInput)
    || containsPathTraversal(dirtyInput)) {
    return sanitize(dirtyInput, sanitizeOptions);
  }
  return dirtyInput;
}

export default {
  validateEmail,
  validatePassword,
  validName,
  validatePhone,
  months,
  passwordValidate,
  replaceAmpersandWithAnd,
  isValidReport,
  isValidReportResonse,
  isValidPlaywrightReport,
  isValidCypressReport,
  isValidHtmlReport,
  sanitizeInput,
  isDisposableEmail,
};
