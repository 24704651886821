/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Link, useNavigate, useSearchParams,
} from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import googleLogo from '../../assets/images/google-logo.svg';
import eyeIcon from '../../assets/images/eye.svg';
import eyeCloseIcon from '../../assets/images/eye-close.png';
import dashboardImg from '../../assets/images/Dashboard.png';
import analyticsDashboardImg from '../../assets/images/Web-Analytics-Dashboard.png';
import commonUtils from '../../utils/common/common';
import userService from '../../services/user.service';
import route from '../../utils/route';
import { getGoogleUrl } from '../../utils/google';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import Loader from '../../utils/loader/Loader';
import ReCaptcha from '../ReCaptcha';
import useMeta from '../../utils/useMeta';

const LoginPage = () => {
  useMeta('Login', 'Access your TestReport.io account to manage testing projects and reports.');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [capsLock, setCapsLock] = useState(false);
  const [navigateSnackbarState, setNavigateSnackbarState] = useState({
    navigateText: '',
    severity: '',
    state: false,
    message: '',
  });
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerify = useCallback((captchaToken) => {
    setToken(captchaToken);
  }, [refreshReCaptcha]);

  useEffect(() => {
    setInterval(() => {
      setRefreshReCaptcha((prev) => !prev);
    }, 110000);
  }, [refreshReCaptcha]);

  const initialValues = {
    email: '',
    password: '',
  };

  const signInSchema = Yup.object({
    email: Yup.string().trim().matches(commonUtils.validateEmail, 'Email is invalid').test(
      'not-disposable',
      'Temporary email addresses are not allowed. Please use a valid email to register.',
      (value) => commonUtils.isDisposableEmail(value) === false,
    )
      .required('Please enter your email'),
    password: Yup.string().trim().required('Password is required'),
  });

  const onSignIn = async (values, { setSubmitting, setErrors }) => {
    try {
      setLoading(true);
      const res = await userService.userLogin(values, token);
      if (res?.userResponse) {
        navigate(`/${route.ROUTE_HOME_PAGE}`, { replace: true });
      }
    } catch (err) {
      setErrors({ general: err?.data?.message || 'An error occurred' });
    } finally {
      setRefreshReCaptcha(!refreshReCaptcha);
      setLoading(false);
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: signInSchema,
    onSubmit: onSignIn,
  });

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  // Function to check if Caps Lock is on
  const handleKeyDown = (event) => {
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      setCapsLock(true);
    }
  };

  const handleKeyUp = (event) => {
    if (event.getModifierState && !event.getModifierState('CapsLock')) {
      setCapsLock(false);
    }
  };

  document.body.classList.add('loginBody');

  const verfiyEmail = async () => {
    try {
      setEmailVerify(true);
      await userService.emailVerification(id);
      setNavigateSnackbarState({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Emial Verified Successfully ..!',
      });
      navigate('/login');
    } catch (error) {
      setNavigateSnackbarState({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message || 'An error occurred'}`,
      });
    } finally {
      setEmailVerify(false);
    }
  };

  useEffect(() => {
    if (id) {
      verfiyEmail();
    }
  }, [id]);

  useEffect(() => {
    const passwordField = document.getElementById('password');
    passwordField.addEventListener('keydown', handleKeyDown);
    passwordField.addEventListener('keyup', handleKeyUp);
    return () => {
      passwordField.removeEventListener('keydown', handleKeyDown);
      passwordField.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  if (emailVerify) return <Loader />;

  return (
    <>
      <div className="comman-pagewrap">
        <div className="left-block-details">
          <div className="logo-block-wrap">
            <Link to="/">
              <img src={logo} alt="logo" width="206" height="38" />
            </Link>
          </div>

          <div className="login-detail-block">
            <div className="login-detail-inner">
              <div className="login-detail-block">
                <h2 className="h2">
                  Welcome to
                  <b>
                    {' testreport '}
                  </b>
                </h2>
                <p className="sub-title">Enter your login details below</p>
                <form onSubmit={formik.handleSubmit} className="login-form">
                  <button onClick={() => { window.location.href = getGoogleUrl(); }} className="google-btn" type="button">
                    <img src={googleLogo} alt="google logo" width="28" height="28" />
                    Log in with Google
                  </button>
                  <span className="or-boder-block" />
                  <div className="input-wrap">
                    <label className="label" htmlFor="email">Email Address</label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      id="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <span className="error-msg">{formik.errors.email}</span>
                    ) : null}
                  </div>
                  <div className="input-wrap">
                    <label className="label" htmlFor="password">Password</label>
                    <div className="password-wrap">
                      <input
                        className="form-control password-bk"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter your password"
                        name="password"
                        id="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <div
                        className="password-show"
                        tabIndex="0"
                        role="button"
                        onClick={handlePasswordToggle}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handlePasswordToggle();
                          } else if (e.key === 'CapsLock') {
                            handleKeyDown();
                          }
                        }}
                        onKeyUp={(e) => {
                          if (e.key === 'CapsLock') {
                            handleKeyUp();
                          }
                        }}
                        onKeyPress={(e) => e.preventDefault()}
                      >
                        <img
                          className="eye-show"
                          src={showPassword ? eyeCloseIcon : eyeIcon}
                          alt="toggle visibility"
                          width="20"
                          height="20"
                        />
                      </div>
                    </div>
                    {!formik.errors.password && capsLock && (
                      <span className="error-msg">
                        Caps Lock is on!
                      </span>
                    )}
                    {formik.touched.password && formik.errors.password ? (
                      <span className="error-msg">{formik.errors.password}</span>
                    ) : null}
                    {formik.errors.general && (
                      <div className="error-msg">{formik.errors.general}</div>
                    )}
                  </div>
                  <div className="input-wrap-check">
                    <div className="checkbox-lecf">
                      <input type="checkbox" id="remember" />
                      <label htmlFor="remember">
                        Remember this device
                      </label>
                    </div>
                    <Link to={`/${route.ROUTE_FORGOT}`}>Forgot Password?</Link>
                  </div>
                  <button className="custom-btn" type="submit" disabled={formik.isSubmitting || loading}>
                    {loading ? 'Signing In...' : 'Sign In'}
                  </button>
                  <p className="bottom-link-wrap">
                    New to testreport?
                    <Link to={`/${route.ROUTE_SIGNUP}`} style={{ marginLeft: 8 }}>Create an account</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="right-block-details">
          <div className="login-img-right">
            <h1 className="h1">Test Automation platform for achieving effective outcome</h1>
            <p>Enter your credentials to access your account</p>
            <div className="right-block-detailsimg">
              <img className="img-ds" src={analyticsDashboardImg} alt="Dashboard" width="400" height="280" />
              <img className="sd-img" src={dashboardImg} alt="Web-Analytics-Dashboard" width="640" height="340" />
            </div>
          </div>
        </div>
      </div>
      <ReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} action="Login" />
      {
        navigateSnackbarState.state
        && (
          <NavigateSnackbar
            snackbarObj={navigateSnackbarState}
            setSnackbar={setNavigateSnackbarState}
          />
        )
      }
    </>
  );
};

export default LoginPage;
