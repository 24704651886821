import { useEffect } from 'react';

/**
 * Hook to dynamically update the document's meta title and description
 * @param {string} title - The title to set for the page.
 * @param {string} description - The description to set for the page.
 */
const useMeta = (title, description) => {
  useEffect(() => {
    // Update the document title
    if (title) {
      document.title = `${title} - TestReport.io`;
    }

    // Update the meta description tag
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    } else {
      // If there's no meta description, create one dynamically
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = description;
      document.head.appendChild(newMetaDescription);
    }
  }, [title, description]); // Re-run the effect when title or description changes
};

export default useMeta;
