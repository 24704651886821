/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Dialog,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import framework1 from '../../assets/images/framework1.svg';
import framework2 from '../../assets/images/framewor2.svg';
import framework3 from '../../assets/images/framewor3.svg';
import { DashboardEnum } from '../../utils/constants';
import inviteUserService from '../../services/inviteUser.service';
import userService from '../../services/user.service';
import { uploadFile } from '../../services/uploadFile.service';
import { useCommonContext } from '../header/context';
import Loader from '../../utils/loader/Loader';
import LinearIndeterminate from '../../utils/loader/ProgressBar';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '100%',
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
  },
}));

const renderSeleniumIntegrationSteps = (code) => [
  {
    label: 'Step 1',
    title: 'Copy Required Snippet',
    description: (
      <>
        <Typography>
          Copy the required snippet from TestReport.io.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap', margin: 0 }}>
            <code>{code}</code>
          </Typography>
        </Box>
        <Typography mt={1}>
          Open the
          <code>{' pom.xml '}</code>
          file in your Selenium framework.
        </Typography>
        <Typography>
          Paste the code into your
          <code>{' pom.xml '}</code>
          file to configure the necessary dependencies.
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 2',
    title: 'Set Source File Path',
    description: (
      <>
        <Typography>
          Set the path of the
          <code>{' testng-results.xml '}</code>
          file in your projects where the file generates inside the OutputDirectory tag.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            Example:
            <br />
            <code>{'<sourceFilePath>${project.build.directory}/test-output/testng-results.xml</sourceFilePath>'}</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 3',
    title: 'Set Screenshot Path',
    description: (
      <>
        <Typography>
          If you integrate screenshots, define the
          <code>{' screenshotPath '}</code>
          in your project directory for storing failed case screenshots.
          If not required, remove the
          <code>{' <screenshotImagePath> '}</code>
          tag.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            Example:
            <br />
            <code>{'<screenshotImagePath>${project.build.directory}/test-output/screenshots/</screenshotImagePath>'}</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 4',
    title: 'Run the Project',
    description: (
      <>
        <Typography>
          Execute the following Maven command in your terminal
          to initiate the build and test process:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p" sx={{ whiteSpace: 'pre-wrap', margin: 0 }}>
            Example:
            <br />
            <code>
              {`
mvn clean integration-test

<Run |maven command with your specified goal which you mentioned in pom.xml>`}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 5',
    title: 'Add below line inside your makeScreenshot method, So you’ll get image url as well on report:',
    description: (
      <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
        <Typography variant="body2" component="p">
          <code>
            {`Reporter.log("<a href='" + "https://app.testreport.io/qa-java-api/api/v1/user/getImage/SCREENSHOT/"+
            destFile.getName() + "'> <img src='"+ "https://app.testreport.io/qa-java-api/api/v1/user/getImage/SCREENSHOT/" + destFile.getName()+ "' height='250' width='500'/> ");`}
          </code>
        </Typography>
      </Box>
    ),
  },
];

const renderPlaywrightIntegrationSteps = (code) => [
  {
    label: 'Step 1',
    title: 'Create the Configuration File',
    description: (
      <>
        <Typography>
          First, create a configuration file named
          <code>{' testreport.config.js '}</code>
          in the root directory of your Playwright project.
        </Typography>
        <Typography pt={2}>Code Snippet:</Typography>
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            padding: 2,
            fontFamily: 'monospace',
          }}
        >
          <div>
            <Tooltip title="Copy to Clipboard">
              <ContentCopyIcon
                color="primary"
                style={{ cursor: 'pointer', marginBottom: '10px' }}
                onClick={() => navigator.clipboard.writeText(code)}
              />
            </Tooltip>
            <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap', margin: 0 }}>
              <code>{code}</code>
            </Typography>
          </div>
        </Box>
        <Typography variant="body2" component="p" mt={2}>
          Copy the above code snippet and paste it into the
          <code>
            {' testreport.config.js '}
          </code>
          file.
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 2',
    title: 'Set targetDir',
    description: (
      <>
        <Typography>
          In the configuration file, specify the path where the
          <code>{' index.html '}</code>
          test report file is generated.
        </Typography>
        <Typography pt={2}>Example Path:</Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {"targetDir: 'C:\\Users\\user\\Downloads\\demo\\playwright-report\\',"}
            </code>
          </Typography>
        </Box>
        <Typography pt={2}>
          {`Ensure to use double
          slashes (\\\\) in the path for compatibility.`}
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 3',
    title: 'Set screenshotPath',
    description: (
      <>
        <Typography>
          Point this to the directory
          where screenshots for failed tests are stored.
        </Typography>
        <Typography pt={2}>Example Path:</Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {"screenshotsDir: 'C:\\Users\\user\\Downloads\\demo\\playwright-report\\data\\',"}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 4',
    title: 'Install the TestReport.io Dependency',
    description: (
      <>
        <Typography>
          Next, install the TestReport.io dependency
          by running the following command in your terminal:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              npm install testreport-io
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 5',
    title: 'Add the Upload Script',
    description: (
      <>
        <Typography>
          Add the following script to your
          <code>{' package.json '}</code>
          file to enable uploading your test reports:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {`"scripts": {
                "upload": "testreport-io upload"
              }`}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 6',
    title: 'Run the Upload Command',
    description: (
      <>
        <Typography>
          Finally, upload your test report and screenshots to
          TestReport.io by running the following command in your terminal:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              npm run upload
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
];

const renderCypressIntegrationSteps = (code) => [
  {
    label: 'Step 1',
    title: 'Create the Configuration File',
    description: (
      <>
        <Typography>
          Create a configuration file named
          <code>{' testreport.config.js '}</code>
          in the root directory of your Cypress project.
        </Typography>
        <Typography pt={2}>Code Snippet:</Typography>
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            padding: 2,
            fontFamily: 'monospace',
          }}
        >
          <div>
            <Tooltip title="Copy to Clipboard">
              <ContentCopyIcon
                color="primary"
                style={{ cursor: 'pointer', marginBottom: '10px' }}
                onClick={() => navigator.clipboard.writeText(code)}
              />
            </Tooltip>
            <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap', margin: 0 }}>
              <code>{code}</code>
            </Typography>
          </div>
        </Box>
        <Typography variant="body2" component="p" mt={2}>
          Copy the above code snippet and paste it into the
          <code>
            {' testreport.config.js '}
          </code>
          file.
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 2',
    title: 'Set targetDir',
    description: (
      <>
        <Typography>
          In the configuration file, specify the path where the
          <code>{' index.html '}</code>
          test report file is generated.
        </Typography>
        <Typography pt={2}>Example Path:</Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {"targetDir: 'D:\\Cypress-Framework\\cypress\\results\\cypress-mochawesome-reporter\\',"}
            </code>
          </Typography>
        </Box>
        <Typography pt={2}>
          {`Ensure to use double
          slashes (\\\\) in the path for compatibility.`}
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 3',
    title: 'Set screenshotsDir',
    description: (
      <>
        <Typography>
          Specify the directory path where
          Cypress stores screenshots for failed tests.
        </Typography>
        <Typography pt={2}>Example Path:</Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {"sourceFilePath: ‘D:\\Cypress-Framework\\cypress\\screenshots\\',"}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 4',
    title: 'Set videoDir',
    description: (
      <>
        <Typography>
          Specify the directory path where Cypress saves test videos.
        </Typography>
        <Typography pt={2}>Example Path:</Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {"videoDir: 'D:\\Cypress-Framework\\cypress\\videos\\',"}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 5',
    title: 'Install the TestReport.io Dependency',
    description: (
      <>
        <Typography>
          Next, install the TestReport.io dependency
          by running the following command in your terminal:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              npm install testreport-io
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 6',
    title: 'Add the Upload Script',
    description: (
      <>
        <Typography>
          Add the following script to your
          <code>{' package.json '}</code>
          file to enable uploading your test reports:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {`"scripts": {
                "upload": "testreport-io upload"
              }`}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 7',
    title: 'Run the Upload Command',
    description: (
      <>
        <Typography>
          To, upload your test report and screenshots to
          TestReport.io  use the following command in your terminal:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              npm run upload
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
];

const ProjectSetUp = ({ opend, handleClose }) => {
  const [fileList, setFileList] = useState([]);
  const { countNotification, setCountNotification } = useCommonContext();
  const [errorMessage, setErrorMessage] = useState('');
  const [token, setToken] = useState(null);
  const [projectUrl, setprojectUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();
  const [userDetails, setUserDetails] = useState({});
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [code, setCode] = useState('');

  useEffect(() => {
    if (projectUrl && token && userDetails.selectedFramework) {
      let codeString = '';

      switch (userDetails.selectedFramework) {
        case DashboardEnum.SELENIUM:
          codeString = `
<build>
  <plugins>
    <plugin>
      <groupId>com.decipherzone</groupId>
      <artifactId>qa-parse-maven-plugin</artifactId>
      <version>1.5.13</version>
      <executions>
        <execution>
          <phase>integration-test</phase>
          <configuration>
            <projectBaseUrl>${projectUrl}</projectBaseUrl>
            <screenshotImagePath>/failsafe-reports/chrome/screenshots/</screenshotImagePath>
            <sourceFilePath>/platform-server/testng-results.xml</sourceFilePath>
            <projectAccessKey>${token}</projectAccessKey>
          </configuration>
          <goals>
            <goal>xmltojson</goal>
          </goals>
        </execution>
      </executions>
    </plugin>
  </plugins>
</build>`;
          break;

        case DashboardEnum.PLAYWRIGHT:
          codeString = `module.exports = {
    projectAccessKey: '${token}',
    projectBaseUrl: '${projectUrl}',
    targetDir: '<generated test report index.html directory path>',
    screenshotsDir: '<screenshots directory path>',
  };`;
          break;

        case DashboardEnum.CYPRESS:
          codeString = `module.exports = {
    projectAccessKey: '${token}',
    projectBaseUrl: '${projectUrl}',
    targetDir: '<generated test report index.html directory path>',
    screenshotsDir: '<screenshots directory path>',
    videoDir: '<videos directory path>'
  };`;
          break;

        default:
          codeString = '';
      }

      setCode(codeString);
    }
  }, [projectUrl, token, userDetails]);

  const processFiles = (files) => {
    const validFiles = [];
    let invalidFiles = false;

    files.forEach((file) => {
      if (file.name.endsWith('.html') || file.name.endsWith('.xml')) {
        validFiles.push(file);
      } else {
        invalidFiles = true;
      }
    });

    if (invalidFiles) {
      setErrorMessage('Only .html and .xml files are allowed.');
    } else {
      setErrorMessage('');
    }

    setFileList(validFiles);
  };
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    processFiles(files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    processFiles(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const uploadFilesFunction = async () => {
    const formData = new FormData();
    formData.append('file', fileList[0]);
    try {
      setLoading(true);
      await uploadFile(formData, userDetails.companyId, userDetails.projectId, (udata) => {
        setProgress(Math.round((100 * udata.loaded) / udata.total));
      });
      setCountNotification(countNotification + 1);
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'File Uploaded Successfully',
      });
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const getUserDetails = async () => {
    try {
      const user = await userService.userInfo();
      setUserDetails({
        projectId: user.company.projectResponses[0].projectId,
        companyId: user.company.companyId,
        companyName: user.company.companyName,
        projectName: user.company.projectResponses[0].projectName,
        selectedFramework: user.company.projectResponses[0].framework,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const accessToken = async () => {
      if (!token && userDetails.companyId && userDetails.projectId) {
        try {
          const res = await inviteUserService.accessToken(
            userDetails.companyId,
            userDetails.projectId,
          );
          if (res) {
            setToken(res?.accessKey);
            setprojectUrl(res?.projectBasicUrl);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    accessToken();
  }, [userDetails]);

  const renderIntegrationContent = () => {
    switch (userDetails?.selectedFramework) {
      case DashboardEnum.SELENIUM:
        return renderSeleniumIntegrationSteps(code);
      case DashboardEnum.PLAYWRIGHT:
        return renderPlaywrightIntegrationSteps(code);
      case DashboardEnum.CYPRESS:
        return renderCypressIntegrationSteps(code);
      default:
        return [];
    }
  };

  const onFinish = async () => {
    try {
      const res = await userService.onboarding();
      if (res) {
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  if (loading) return <Loader />;
  return (
    <>
      {/* <header className="header-sec">
        <div className="header-wrap">
          <div className="logo-wrap">
            <Link to="/">
              <img src={logo} alt="logo" width="206" height="38" />
            </Link>
          </div>
          <div className="header-right-info">
            <span className="nap">Need any help?</span>
          </div>
        </div>
      </header> */}
      <StyledDialog
        open={opend}
        onClose={handleClose}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="multistep-main-wrap">
          <div className="multistep-2-block-details">
            <div className="multistep-detail-block">
              <h1 className="h2">Project Integration and Report Upload</h1>
              <div style={{ display: 'flex', gap: '20px' }}>
                <div className="input-wrap" style={{ width: '100%' }}>
                  <p style={{ fontSize: '15px' }} htmlFor="companyName">Company Name</p>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    placeholder="Enter your company name"
                    name="companyName"
                    id="companyName"
                    value={userDetails?.companyName}
                  />
                </div>
                <div className="input-wrap" style={{ width: '100%' }}>
                  <p style={{ fontSize: '15px' }} htmlFor="projects">Project Name</p>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    placeholder="Enter your project name"
                    name="projects"
                    id="projects"
                    value={userDetails?.projectName}
                  />
                </div>
              </div>
              <div className="select-framework-wrap">
                <p style={{ fontSize: '15px' }}>Selected Framework</p>
                <div className="select-framework-row">
                  {userDetails.selectedFramework === DashboardEnum.SELENIUM && <div
                    className="framework-box active"
                  >
                    <img src={framework1} alt="framework1" width="85" height="85" />
                    <span>Selenium</span>
                  </div>}
                  {userDetails.selectedFramework === DashboardEnum.PLAYWRIGHT && <div
                    className="framework-box active"
                  >
                    <img src={framework2} alt="framework2" width="85" height="85" />
                    <span>Playwright</span>
                  </div>}
                  {userDetails.selectedFramework === DashboardEnum.CYPRESS && <div
                    className="framework-box active"
                  >
                    <img src={framework3} alt="framework3" width="85" height="85" />
                    <span>Cypress</span>
                  </div>}
                </div>
              </div>
              <div className="multistep-detail-block">
                {/* <ProjectIntegration
                  token={token}
                  projectUrl={projectUrl}
                  framework={userDetails.selectedFramework}
                /> */}
                <Box
                  sx={{
                    backgroundColor: '#f5f5f5',
                    padding: 2,
                    borderRadius: 2,
                    boxShadow: 1,
                    fontFamily: 'monospace',
                  }}
                >
                  <div>
                    <Tooltip title="Copy to Clipboard">
                      <ContentCopyIcon
                        color="primary"
                        style={{ cursor: 'pointer', marginBottom: '10px' }}
                        onClick={() => navigator.clipboard.writeText(code)}
                      />
                    </Tooltip>
                    <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                      <code>{code}</code>
                    </Typography>
                  </div>
                </Box>
              </div>
              <div className="my-5 text-center font-weight-bold">OR</div>
              <div className="upload-file-area my-5">
                <div
                  className="drag-drop-area"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <input
                    type="file"
                    id="fileInput"
                    accept=".html, .xml"
                    multiple
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                  />
                  <p>
                    Drag & Drop or
                    {' '}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById('fileInput').click();
                      }}
                    >
                      Browse
                    </a>
                  </p>
                  <small>Support: .html, .xml</small>
                  <div id="fileList" className="mt-3">
                    {fileList.length > 0
                      && fileList.map((file, index) => (
                        <div key={index}>{file.name}</div>
                      ))}
                  </div>
                  <div
                    className="text-center"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {loading && <LinearIndeterminate progress={progress} />}
                  </div>
                  <span className="error-msg">{errorMessage}</span>
                </div>
                <button
                  type="button"
                  className={`custom-btn mt-3 mx-auto px-1 py-2 ${fileList.length === 0 ? 'd-none' : ''
                  }`}
                  onClick={uploadFilesFunction}
                  disabled={fileList.length === 0 || loading}
                >
                  Upload
                </button>
              </div>
              <div className="next-prev-btn-wrap justify-content-end">
                <button type="button" onClick={onFinish} className="next-prev-btn justify-content-end active" disabled={loading}>
                  <span>Complete Onboarding</span>
                </button>
              </div>
            </div>
          </div>
          <div className="multistep-2-block-right">
            <Box sx={{ width: '100%', padding: 4 }}>
              {userDetails?.selectedFramework === DashboardEnum.SELENIUM && <>
                <Box>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>Prerequisite:</Typography>
                  <Box pl={1}>
                    <Typography variant="h6" mt={2} sx={{ fontWeight: 'bold', color: '#333' }}>1. Add the Surefire Plugin:</Typography>
                    <Typography my={1}>
                      Navigate to the Plugin Management section in your pom.xml file,
                      include the Surefire plugin, and specify the path to your XML configuration
                      file.
                    </Typography>
                    <Typography variant="h6" mt={2} sx={{ fontWeight: 'bold', color: '#333' }}>2. Add the Failsafe Plugin:</Typography>
                    <Typography my={1}>
                      {`Ensure the Maven Failsafe plugin is added outside the
                      <pluginManagement> section in your pom.xml file. also specify the path
                      to your XML configuration file.`}
                    </Typography>
                  </Box>
                </Box>
                </>}
              <Typography mb={2} variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                {(userDetails?.selectedFramework === DashboardEnum.SELENIUM
                  && 'Steps to add Selenium-Maven project') || (userDetails?.selectedFramework === DashboardEnum.PLAYWRIGHT
                    && 'Steps to add Playwright project') || (userDetails?.selectedFramework === DashboardEnum.CYPRESS
                      && 'Steps to add Cypress project')}
              </Typography>
              <Stepper activeStep={-1} orientation="vertical" style={{ paddingBottom: '100px' }}>
                {renderIntegrationContent()?.length
                  ? renderIntegrationContent()?.map((step, index) => (
                    <Step key={index}>
                      <div className="copy-icon">
                        <StepLabel>
                          <Typography variant="p" sx={{ fontWeight: 'light', color: '#333' }}>
                            {step.label}
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                            {step.title}
                          </Typography>
                        </StepLabel>
                        {step.label === 'Step 1' && <Tooltip title="Copy to Clipboard">
                          <ContentCopyIcon
                            color="primary"
                            style={{ cursor: 'pointer', marginBottom: '10px' }}
                            onClick={() => navigator.clipboard.writeText(code)}
                          />
                        </Tooltip>}
                      </div>
                      <Box sx={{ marginTop: 2, paddingLeft: 4 }}>
                        <Typography variant="body1" sx={{ color: '#555' }}>
                          {step.description}
                        </Typography>
                      </Box>
                    </Step>
                  )) : <p>Select a valid framework to see integration instructions.</p>}
              </Stepper>
            </Box>
          </div>
        </div>
      </StyledDialog>
      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </>
  );
};

export default ProjectSetUp;

ProjectSetUp.propTypes = {
  opend: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

ProjectSetUp.defaultProps = {
  opend: PropTypes.bool,
};
