import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Breadcrumbs,
  CssBaseline,
  Grid,
  CardContent,
  Button,
  Typography,
  Card,
} from '@mui/material';
import { Container } from '@mui/system';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';
import { useCommonContext } from '../header/context';
import {
  UserPermission,
} from '../../utils/constants';
import { projectStyles } from '../header/style';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import SlackIcon from '../../assets/slack-svgrepo-com.svg';
import JiraIcon from '../../assets/images/jira-logo.png';
import route from '../../utils/route';
import {
  addSlackWebHook,
} from '../../services/integrations';
import IntegrationModal from './Dialog';
import JiraIntegrationModal from './JiraIntegrationModal';
import companyService from '../../services/company.service';
import reportService from '../../services/report.service';
import useMeta from '../../utils/useMeta';

function Integrations() {
  useMeta('Integrations', 'Connect your favorite tools and improve your workflow.');
  const {
    user,
    setActiveItem,
  } = useCommonContext();
  const classes = projectStyles();
  const navigate = useNavigate();
  const [openIntegrationModal, setOpenIntegrationModal] = useState(false);
  const [jiraModal, setJiraModal] = useState(false);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [projectsDropdown, setProjectsDropdown] = useState([]);

  const handleSlackConfig = async (payload) => {
    if (payload.slackUrl) {
      try {
        const res = await addSlackWebHook(payload);
        if (res) {
          setOpenIntegrationModal(false);
          navigate(`/${route.ROUTE_PERSONALINFO}`, { state: { showSlack: true, message: 'Slack webhook added successfully!' } });
        }
      } catch (err) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${err?.data?.message}`,
        });
      }
    }
  };

  useEffect(() => {
    setActiveItem('Integration');
  }, []);

  const handleModalClose = () => {
    setOpenIntegrationModal(false);
  };
  const handleJiraClose = () => {
    setJiraModal(false);
  };

  const fetchCompanies = useCallback(async () => {
    try {
      const res = await companyService.getAll();
      setCompanyDropdown(res);
      setCompanyId(res[0]?.companyId || '');
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
  }, []);

  const fetchProjects = useCallback(async () => {
    if (companyId) {
      try {
        const res = await reportService.getProjectById(companyId);
        setProjectsDropdown(res);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error?.data?.message || 'Failed to fetch projects'}`,
        });
      }
    }
  }, [companyId]);

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (user?.role?.userPermissionsList.includes(UserPermission.JIRA_INTEGRATION)) {
      fetchProjects();
    }
  }, [companyId, user?.role?.roleId]);

  return (
    <>
      <Container maxWidth={false} className={classes.root}>
        <CssBaseline />
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" marginBottom={2}>
          <Typography color="#666666" onClick={() => navigate(`/${route.ROUTE_DASHBOARD}`)} sx={{ cursor: 'pointer' }}>Dashboard</Typography>
          <Typography color="#666666">Integration</Typography>
        </Breadcrumbs>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h4">
            Integration
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={4}>
            <Box sx={{ padding: '20px' }}>
              <Grid container spacing={2}>
                {/* Slack Card */}
                <Grid item xs={12} md={4} lg={2}>
                  <Card variant="outlined">
                    <CardContent sx={{ paddingTop: '20px' }}>
                      <Grid container direction="column" alignItems="flex-start">
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <img width="50px" height="50px" src={SlackIcon} alt="slack" />
                          <Button target="_blank" href="https://testreport.tawk.help/article/generating-a-slack-webhook-url" sx={{ width: '45%' }}>Support</Button>
                        </Box>
                        <Typography variant="h6" fontWeight={600} align="center" mt={1}>
                          Slack
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, marginTop: '10px' }}>
                          <Button onClick={() => setOpenIntegrationModal(true)} variant="contained" color="primary">
                            Configure
                          </Button>
                          <Button onClick={() => navigate(`/${route.ROUTE_PERSONALINFO}`, { state: { showSlack: true } })} variant="outlined" color="primary">
                            View
                          </Button>
                        </Box>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Jira Card */}
                <Grid item xs={12} md={4} lg={2}>
                  <Card variant="outlined">
                    <CardContent sx={{ paddingTop: '20px' }}>
                      <Grid container direction="column" alignItems="flex-start">
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <img width="50px" height="50px" src={JiraIcon} alt="jira" />
                          <Button target="_blank" href="https://testreport.tawk.help/article/integrating-jira-account-with-testreport" sx={{ width: '45%' }}>Support</Button>
                        </Box>
                        <Typography variant="h6" fontWeight={600} align="center" mt={1}>
                          Jira
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, marginTop: '10px' }}>
                          <Button onClick={() => setJiraModal(true)} variant="contained" color="primary">
                            Configure
                          </Button>
                          <Button onClick={() => navigate(`/${route.ROUTE_PERSONALINFO}`, { state: { showJira: true } })} variant="outlined" color="primary">
                            View
                          </Button>
                        </Box>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {
          snackbar.state
          && (
            <NavigateSnackbar
              snackbarObj={snackbar}
              setSnackbar={setSnackbar}
            />
          )
        }
      </Container>
      <IntegrationModal
        open={openIntegrationModal}
        handleClose={handleModalClose}
        onConfirm={handleSlackConfig}
        companyDropdown={companyDropdown}
        projects={projectsDropdown}
        companyId={companyId}
        setCompanyId={setCompanyId}
      />
      <JiraIntegrationModal
        open={jiraModal}
        handleClose={handleJiraClose}
        companyDropdown={companyDropdown}
        projects={projectsDropdown}
        companyId={companyId}
        setCompanyId={setCompanyId}
      />
    </>
  );
}

export default Integrations;
