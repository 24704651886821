import React, { useState, useEffect } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/system';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import route from '../../utils/route';
import CompanyModal from '../Modal/Modal';
import companyService from '../../services/company.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { InviteStatus, UserPermission, UserStatus } from '../../utils/constants';
import { useCommonContext } from '../header/context';
import { CustomPagination, projectStyles, StyledTablePagination } from '../header/style';
import ActionModal from '../ActionModal/ActionModal';
import commonUtils from '../../utils/common/common';
import useMeta from '../../utils/useMeta';

function Companies() {
  useMeta('Company List', 'Manage your company details and team access.');
  const [loading, setLoading] = useState(false);
  // const [openModal, setOpenModal] = useState(false);
  const [companydata, setCompanyData] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [opend, setOpend] = React.useState(false);
  const [eventId, setEventId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({ companyName: '', companyStatus: UserStatus.STATUS });
  const [companyEdit, setCompanyEdit] = useState('');
  const [companyShow, setcompanyShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState();
  const [isDeleteCompany, setIsDeleteCompany] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setFormData({ companyName: '' });
    setcompanyShow(false);
    setCompanyEdit('');
  };
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const navigate = useNavigate();
  const {
    user,
  } = useCommonContext();

  const classes = projectStyles();

  const getAllCompany = async () => {
    try {
      setLoading(true);
      const res = await companyService.getAllCompany(page > 0 ? page - 1 : 0, rowsPerPage);
      if (res) {
        setCompanyData(res.content);
        setTotalPages(res.totalPages);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllCompany();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onHandleDelete = async () => {
    if (eventId) {
      try {
        await companyService.deleteCompany(eventId);
        setOpend(false);
        getAllCompany();
      } catch (error) {
        setOpend(false);
      } finally {
        setOpend(false);
      }
    }
  };

  const onDelete = async (id) => {
    if (id) {
      setOpend(true);
      setIsDeleteCompany(true);
      setIsBlock(false);
      setIsActive(false);
      setEventId(id);
    }
  };

  const onChange = (e) => {
    const { value } = e.target;
    if (companyShow) {
      setCompanyEdit({ ...companyEdit, companyName: value });
    } else {
      setFormData({ ...formData, companyName: commonUtils.sanitizeInput(value) });
    }
  };
  const handleSubmitForm = async () => {
    try {
      setLoading(true);
      await companyService.addCompany(formData);
      handleClose();
      getAllCompany();
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Company Created ..!',
      });
      setTimeout(() => {
        navigate(`/${route.ROUTE_COMPANIES}`);
      }, 2000);
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err.data.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleUpdateForm = async () => {
    const { companyId, companyName } = companyEdit;
    try {
      setLoading(true);
      await companyService.updateCompany({ companyId, companyName });
      handleClose();
      getAllCompany();
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const editDetail = (item) => {
    setCompanyEdit(item);
    setcompanyShow(true);
    setOpen(true);
  };

  const onHandleBlock = async () => {
    if (eventId) {
      const payload = {
        companyId: eventId.companyId,
        status: eventId.companyStatus === InviteStatus.ACTIVE
          ? InviteStatus.BLOCKED : InviteStatus.ACTIVE,
      };
      try {
        setLoading(true);
        const response = await companyService.updateCompanyStatus(payload);
        getAllCompany();
        setSnackbar({
          navigateText: '',
          severity: 'success',
          state: true,
          message: `${response}`,
        });
        setOpend(false);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.message}`,
        });
        setOpend(false);
      } finally {
        setLoading(false);
        setOpend(false);
      }
    }
  };

  const blockCompany = async (item) => {
    if (item) {
      setOpend(true);
      setIsBlock(item.companyStatus === InviteStatus.ACTIVE);
      setIsActive(item.companyStatus === InviteStatus.BLOCKED);
      setIsDeleteCompany(false);
      setEventId(item);
      setCurrentStatus(item.companyStatus === InviteStatus.ACTIVE
        ? InviteStatus.BLOCK : InviteStatus.ACTIVE);
    }
  };

  const handleCloseModal = () => {
    setOpend(false);
    setFormData({ companyName: '', companyStatus: UserStatus.STATUS });
    setIsDeleteCompany(false);
    setIsBlock(false);
    setIsActive(false);
    setEventId(null);
    setCurrentStatus('');
  };

  return (
    <>
      <Container maxWidth={false} className={classes.root}>
        <CssBaseline />
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" marginBottom={2}>
          <Typography color="#666666" onClick={() => navigate(`/${route.ROUTE_DASHBOARD}`)} sx={{ cursor: 'pointer' }}>Dashboard</Typography>
          <Typography color="#666666">Company</Typography>
        </Breadcrumbs>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h4">
            Companies
          </Typography>
          {user?.role?.userPermissionsList.includes(UserPermission.COMPANY) && <Button
            variant="contained"
            disabled={loading}
            className={classes.actionButton}
            onClick={() => setOpen(true)}
          >
            Create New Company
          </Button>}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={4}>
            <Paper className={classes.summaryBox}>
              <Box className={classes.summaryBoxIn}>
                <TableContainer component={Paper} className={classes.tableContainer}>
                  {user?.role?.userPermissionsList.includes(UserPermission.COMPANY)
                    ? <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.headerCell}>Name</TableCell>
                          <TableCell className={classes.headerCell}>User Name</TableCell>
                          <TableCell className={classes.headerCell}>Email</TableCell>
                          <TableCell className={classes.headerCell}>Status</TableCell>
                          {user?.role?.userPermissionsList.includes(UserPermission.COMPANY)
                            && <TableCell className={classes.headerCell}>Action</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? <>
                          <TableRow>
                            <TableCell><Skeleton animation="wave" /></TableCell>
                            <TableCell><Skeleton animation="wave" /></TableCell>
                            <TableCell><Skeleton animation="wave" /></TableCell>
                            <TableCell><Skeleton animation="wave" /></TableCell>
                            <TableCell><Skeleton animation="wave" /></TableCell>
                          </TableRow>
                        </> : <>
                          {companydata?.length && companydata?.map((item) => (
                            <TableRow key={item.companyId}>
                              <TableCell>
                                <Typography variant="body1">{item.companyName}</Typography>
                              </TableCell>
                              <TableCell>{item.userResponse.username}</TableCell>
                              <TableCell>{item.userResponse.email}</TableCell>
                              <TableCell>{item.companyStatus}</TableCell>
                              {user?.role?.userPermissionsList.includes(UserPermission.COMPANY)
                                && <TableCell>
                                  <Box display="flex" justifyContent="flex-start">
                                    <Tooltip title="Edit">
                                      <IconButton
                                        style={{ color: '#1D6AE5' }}
                                        disabled={item?.companyStatus === InviteStatus.BLOCKED}
                                        onClick={() => editDetail(item)}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        color="error"
                                        onClick={() => onDelete(item?.companyId)}
                                        disabled={item?.companyStatus === InviteStatus.BLOCKED}
                                      >
                                        <CancelOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title={item.companyStatus === InviteStatus.ACTIVE
                                      ? 'Block' : 'Active'}
                                    >
                                      <IconButton
                                        style={{ color: '#FFC72B' }}
                                        onClick={() => blockCompany(item)}
                                      >
                                        {
                                          item.companyStatus === InviteStatus.ACTIVE
                                            ? <BlockOutlinedIcon /> : <CheckCircleOutlineIcon />
                                        }
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </TableCell>}
                            </TableRow>
                          ))}
                        </>}
                      </TableBody>
                    </Table> : <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.headerCell}>Name</TableCell>
                          <TableCell className={classes.headerCell}>User Name</TableCell>
                          <TableCell className={classes.headerCell}>Email</TableCell>
                          <TableCell className={classes.headerCell}>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {companydata?.length ? companydata?.map((item) => (
                          <TableRow key={item?.companyId}>
                            <TableCell>
                              <Typography variant="body1">{item?.companyName}</Typography>
                            </TableCell>
                            <TableCell>{item?.userResponse?.username}</TableCell>
                            <TableCell>{item?.userResponse?.email}</TableCell>
                            <TableCell>{item?.companyStatus}</TableCell>
                          </TableRow>
                        )) : <TableRow>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                        </TableRow>}
                      </TableBody>
                    </Table>}
                  {totalPages > 0 ? (
                    <Box className={classes.paginationContainer}>
                      <StyledTablePagination
                        count={totalPages * rowsPerPage}
                        totalPages={totalPages}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        ActionsComponent={() => <CustomPagination
                          count={totalPages}
                          page={page}
                          onChange={handleChangePage}
                          variant="outlined"
                          shape="rounded"
                          showFirstButton
                          showLastButton
                        />}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Box>
                  ) : <></>}
                </TableContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        {opend && (
          <ActionModal
            handleClose={handleCloseModal}
            opend={opend}
            handleAction={isDeleteCompany ? onHandleDelete : onHandleBlock}
            isDelete={isDeleteCompany}
            isBlock={isBlock}
            isActive={isActive}
            action={currentStatus}
          />
        )}
        {open && (
          <CompanyModal
            open={open}
            handleClose={handleClose}
            data={formData}
            onChange={onChange}
            handleSubmitForm={handleSubmitForm}
            handleUpdateForm={handleUpdateForm}
            companyEdit={companyEdit}
            companyShow={companyShow}
          />
        )}
        {
          snackbar.state
          && (
            <NavigateSnackbar
              snackbarObj={snackbar}
              setSnackbar={setSnackbar}
            />
          )
        }
      </Container>
    </>
  );
}

export default Companies;
