import React, { useState } from 'react';
import {
  TextField,
  Box,
  Grid,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import {
  TextFieldBox,
} from './style';
import { useCommonContext } from '../header/context';
import inviteUserService from '../../services/inviteUser.service';
import commonUtils from '../../utils/common/common';
import { UserPermission, UserPermissionsObject } from '../../utils/constants';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
    padding: '20px',
  },
  '& .MuiDialogTitle-root': {
    fontWeight: 600,
    fontSize: '24px',
  },
  '.action-button': {
    backgroundColor: '#1d6ae5',
    boxShadow: 'none',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#165bb5',
    },
  },
}));

const ProjectInvite = ({
  isOpen,
  onClose,
  roleList,
  setSnackbar,
  projectDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentRole, setCurrentRole] = useState({});

  const {
    user,
  } = useCommonContext();

  const inviteUser = async (data, action) => {
    const request = {
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      projectId: (user?.role?.userPermissionsList.includes(UserPermission.INVITE_USER)
      && (Object.keys(currentRole).length > 0 && !currentRole?.userPermissionsList?.includes('INVITE_USER'))) ? projectDetails?.projectId : '',
      roleId: data?.roleId,
      companyId: projectDetails?.companyId,
    };

    try {
      setLoading(true);
      await inviteUserService.inviteUserByEmail(request);
      action.resetForm();
      onClose();
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Please Check Your Email...',
      });
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err?.data?.message || 'Something went wrong.'}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    projectName: '',
    companyName: '',
    roleId: '',
  };

  const inviteUserSchema = Yup.object({
    firstName: Yup.string().trim().matches(commonUtils.validName, 'Please enter valid Firstname').min(2, 'Must include more than one character')
      .required('Firstname is required'),
    lastName: Yup.string().trim().matches(commonUtils.validName, 'Please enter valid Lastname').min(2, 'Must include more than one character')
      .required('Lastname is required'),
    email: Yup.string().trim().matches(commonUtils.validateEmail, 'Please enter valid email').test(
      'not-disposable',
      'Temporary email addresses are not allowed. Please use a valid email to register.',
      (value) => commonUtils.isDisposableEmail(value) === false,
    )
      .required('Email is required'),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: inviteUserSchema,
    onSubmit: (val, action) => inviteUser(val, action),
  });

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <Box>
        <DialogTitle>Invite Team Member</DialogTitle>
        <Divider />
        <form
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <Grid item>
              <Grid display="flex" gap={2}>
                <TextFieldBox>
                  <TextField
                    fullWidth
                    error={touched.firstName && errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    size="small"
                    value={values?.firstName || ''}
                    label="First Name"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </TextFieldBox>
                <TextFieldBox>
                  <TextField
                    fullWidth
                    error={touched.lastName && errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    size="small"
                    value={values?.lastName || ''}
                    label="Last Name"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </TextFieldBox>
              </Grid>
              <Grid style={{ paddingBottom: '1px' }}>
                <TextFieldBox>
                  <TextField
                    fullWidth
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                    size="small"
                    value={values.email}
                    label="Email Address"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </TextFieldBox>
              </Grid>
              <TextFieldBox>
                <FormControl fullWidth size="small">
                  <InputLabel size="small">Role</InputLabel>
                  <Select
                    name="roleId"
                    value={values?.roleId || ''}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('projectId', []);
                      setCurrentRole(roleList.find((i) => i?.roleId === e?.target?.value) || {});
                      handleChange(e);
                    }}
                    label="Role"
                    size="small"
                  >
                    {roleList?.map((role) => (
                      <MenuItem value={role?.roleId} key={role?.roleId}>
                        {role?.roleName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TextFieldBox>
              <Grid display="flex" gap={2} mb={2}>
                <TextFieldBox>
                  <TextField
                    fullWidth
                    size="small"
                    value={projectDetails?.companyName || ''}
                    label="Company"
                    name="companyName"
                    disabled
                  />
                </TextFieldBox>
                {(user?.role?.userPermissionsList.includes(UserPermission.INVITE_USER)
              && (Object.keys(currentRole).length > 0 && !currentRole?.userPermissionsList?.includes('INVITE_USER')))
                  ? <TextFieldBox>
                    <TextField
                      fullWidth
                      size="small"
                      value={projectDetails?.projectName || ''}
                      label="Project"
                      name="projectName"
                      disabled
                    />
                  </TextFieldBox> : <></> }
              </Grid>
              <Grid item lg={12} xs={12} sx={{ margin: 'auto' }}>
                <Grid
                  lg={12}
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    border: '1px solid #e0e0e0',
                    p: 1,
                    borderRadius: '4px',
                  }}
                >
                  <Grid item lg={4} xs={12}>
                    <FormControlLabel control={<Checkbox name="VIEW_REPORT" checked={Boolean(currentRole?.userPermissionsList?.includes('VIEW_REPORT'))} disabled={!currentRole?.userPermissionsList?.includes('VIEW_REPORT')} />} label={UserPermissionsObject.VIEW_REPORT} />
                    <FormControlLabel control={<Checkbox name="UPLOAD_REPORT" checked={Boolean(currentRole?.userPermissionsList?.includes('UPLOAD_REPORT'))} disabled={!currentRole?.userPermissionsList?.includes('UPLOAD_REPORT')} />} label={UserPermissionsObject.UPLOAD_REPORT} />
                    <FormControlLabel control={<Checkbox name="INVITE_USER" checked={Boolean(currentRole?.userPermissionsList?.includes('INVITE_USER'))} disabled={!currentRole?.userPermissionsList?.includes('INVITE_USER')} />} label={UserPermissionsObject.INVITE_USER} />
                    <FormControlLabel control={<Checkbox name="TEAM" checked={Boolean(currentRole?.userPermissionsList?.includes('TEAM'))} disabled={!currentRole?.userPermissionsList?.includes('TEAM')} />} label={UserPermissionsObject.TEAM} />
                    <FormControlLabel control={<Checkbox name="COMPANY" checked={Boolean(currentRole?.userPermissionsList?.includes('COMPANY'))} disabled={!currentRole?.userPermissionsList?.includes('COMPANY')} />} label={UserPermissionsObject.COMPANY} />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <FormControlLabel control={<Checkbox name="PROJECT" checked={Boolean(currentRole?.userPermissionsList?.includes('PROJECT'))} disabled={!currentRole?.userPermissionsList?.includes('PROJECT')} />} label={UserPermissionsObject.PROJECT} />
                    <FormControlLabel control={<Checkbox name="INVITE_PROJECT" checked={Boolean(currentRole?.userPermissionsList?.includes('INVITE_PROJECT'))} disabled={!currentRole?.userPermissionsList?.includes('INVITE_PROJECT')} />} label={UserPermissionsObject.INVITE_PROJECT} />
                    <FormControlLabel control={<Checkbox name="INVITE_REPORT" checked={Boolean(currentRole?.userPermissionsList?.includes('INVITE_REPORT'))} disabled={!currentRole?.userPermissionsList?.includes('INVITE_REPORT')} />} label={UserPermissionsObject.INVITE_REPORT} />
                    <FormControlLabel control={<Checkbox name="ROLE" checked={Boolean(currentRole?.userPermissionsList?.includes('ROLE'))} disabled={!currentRole?.userPermissionsList?.includes('ROLE')} />} label={UserPermissionsObject.ROLE} />
                    <FormControlLabel control={<Checkbox name="MAVEN_PLUGIN" checked={Boolean(currentRole?.userPermissionsList?.includes('MAVEN_PLUGIN'))} disabled={!currentRole?.userPermissionsList?.includes('MAVEN_PLUGIN')} />} label={UserPermissionsObject.MAVEN_PLUGIN} />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <FormControlLabel control={<Checkbox name="GUEST_USER" checked={Boolean(currentRole?.userPermissionsList?.includes('GUEST_USER'))} disabled={!currentRole?.userPermissionsList?.includes('GUEST_USER')} />} label={UserPermissionsObject.GUEST_USER} />
                    <FormControlLabel control={<Checkbox name="SLACK_INTEGRATION" checked={Boolean(currentRole?.userPermissionsList?.includes('SLACK_INTEGRATION'))} disabled={!currentRole?.userPermissionsList?.includes('SLACK_INTEGRATION')} />} label={UserPermissionsObject.SLACK_INTEGRATION} />
                    <FormControlLabel control={<Checkbox name="JIRA_INTEGRATION" checked={Boolean(currentRole?.userPermissionsList?.includes('JIRA_INTEGRATION'))} disabled={!currentRole?.userPermissionsList?.includes('JIRA_INTEGRATION')} />} label={UserPermissionsObject.JIRA_INTEGRATION} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ marginTop: '10px' }}>
            <Button className="action-button" type="submit" disabled={loading} variant="contained">
              Invite
            </Button>
            <Button onClick={onClose} disabled={loading}>Cancel</Button>
          </DialogActions>
        </form>
      </Box>
    </StyledDialog>
  );
};

export default ProjectInvite;

ProjectInvite.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  roleList: PropTypes.arrayOf(),
  projectDetails: PropTypes.shape({
    projectId: PropTypes?.number,
    companyId: PropTypes?.number,
    projectName: PropTypes?.string,
    companyName: PropTypes?.string,
  }),
};

ProjectInvite.defaultProps = {
  isOpen: false,
  roleList: [],
  projectDetails: {},
};
